// Authors note:
// Email notifications are disabled for now.
// This is a work in progress and will be updated in the future.

import {
  Card,
  CardContent,
  CardHeader,
  CardDescription,
  CardTitle,
} from '@fluency/ui/components/ui/card';
// import { NotificationChannel, NotificationType } from './types/types';
import { Checkbox } from '@fluency/ui/components/ui/checkbox';
import { AlertTriangle, Loader2, HelpCircle } from 'lucide-react';
import { Alert, AlertDescription } from '@fluency/ui/components/ui/alert';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { Progress } from '@fluency/ui/components/ui/progress';
import { useNotificationPreferences } from './hooks/useNotificationPreferences';
import { NOTIFICATION_CATEGORIES } from './types/types';

export const NotificationPreferences = () => {
  const {
    // preferences,
    isLoading,
    error,
    // updatePreference,
    isUpdating,
    updateProgress,
    updateError,
  } = useNotificationPreferences();

  // const handleChannelToggle = (
  //   notificationType: NotificationType,
  //   channel: NotificationChannel,
  //   currentChannels: NotificationChannel[]
  // ) => {
  //   const updatedChannels = currentChannels.includes(channel)
  //     ? currentChannels.filter((c) => c !== channel)
  //     : [...currentChannels, channel];

  //   if (!updatedChannels.includes(NotificationChannel.IN_APP)) {
  //     updatedChannels.push(NotificationChannel.IN_APP);
  //   }

  //   updatePreference({ notificationType, channels: updatedChannels });
  // };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-muted-foreground" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertTriangle className="h-4 w-4" />
        <AlertDescription>
          Failed to load notification preferences. Please try again later.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-lg font-medium">Notification Preferences</h1>
        <p className="text-sm text-muted-foreground mt-1">
          Manage how you want to receive notifications for different events
        </p>
      </div>

      {updateError && (
        <Alert variant="destructive">
          <AlertTriangle className="h-4 w-4" />
          <AlertDescription>{updateError.message}</AlertDescription>
        </Alert>
      )}

      {isUpdating && updateProgress > 0 && (
        <Progress value={updateProgress} className="h-2" />
      )}

      <TooltipProvider>
        {NOTIFICATION_CATEGORIES.map((category) => (
          <Card key={category.name} className="border rounded-lg">
            <CardHeader className="pb-3">
              <CardTitle className="text-md font-medium">
                {category.name}
              </CardTitle>
              <CardDescription className="text-[14px] text-muted-foreground">
                {category.description}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-1">
                <div className="grid grid-cols-[1fr,100px] gap-6 py-2 text-sm text-muted-foreground font-medium">
                  <div>Notification Type</div>
                  <div className="text-center">In App</div>
                  {/* <div className="text-center">Email</div> */}
                </div>

                <div className="divide-y divide-border">
                  {category.types.map(({ type, label, description }) => {
                    // const preference = preferences.find(
                    //   (p) => p.notificationType === type
                    // ) ?? {
                    //   channels: [NotificationChannel.IN_APP],
                    //   isDefault: true,
                    //   notificationType: type,
                    // };

                    return (
                      <div
                        key={type}
                        className="grid grid-cols-[1fr,100px] gap-6 py-4 items-center"
                      >
                        <div className="flex items-center gap-2">
                          <span className="text-sm">{label}</span>
                          <Tooltip>
                            <TooltipTrigger>
                              <HelpCircle className="h-4 w-4 text-muted-foreground" />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className="w-[240px] text-sm">{description}</p>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                        <div className="flex justify-center">
                          <Checkbox
                            checked={true}
                            disabled
                            aria-label="In-app notifications"
                            className="data-[state=checked]:bg-fluency-600 data-[state=checked]:border-fluency-600"
                          />
                        </div>
                        {/* <div className="flex justify-center">
                          <Checkbox
                            checked={preference.channels.includes(
                              NotificationChannel.EMAIL
                            )}
                            disabled={isUpdating}
                            onCheckedChange={() =>
                              handleChannelToggle(
                                type,
                                NotificationChannel.EMAIL,
                                preference.channels
                              )
                            }
                            aria-label="Email notifications"
                            className="data-[state=checked]:bg-fluency-600 data-[state=checked]:border-fluency-600"
                          />
                        </div> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </TooltipProvider>
    </div>
  );
};

export default NotificationPreferences;
