import { Cross2Icon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { Button } from '@fluency/ui/components/ui/button';
import { Input } from '@fluency/ui/components/ui/input';
import { HideIfViewer } from '@fluency/ui/features/AuthGuard';
import CreateTeamDialog from './CreateTeamDialog';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function DataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().globalFilter !== '';

  return (
    <div className="flex w-full">
      <div className="flex flex-1 items-center space-x-2">
        <div className="relative w-[150px] lg:w-[250px]">
          <Input
            placeholder="Filter teams..."
            value={table.getState().globalFilter}
            onChange={(event) => table.setGlobalFilter(event.target.value)}
            className="h-9 pr-8"
          />
          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => table.setGlobalFilter('')}
              className="absolute right-0 top-0 h-9 w-9 px-2 hover:bg-transparent"
            >
              <Cross2Icon className="h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
      <HideIfViewer>
        <CreateTeamDialog />
      </HideIfViewer>
    </div>
  );
}
