import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { TeamApiResponse } from '../types/types';

const useSelectTeam = (
  teamId: string
): UseQueryResult<TeamApiResponse, Error> => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const { accessToken } = useAuth();

  return useQuery<TeamApiResponse, Error>({
    queryKey: ['team', teamId],
    queryFn: async (): Promise<TeamApiResponse> => {
      const response = await fetch(
        `${apiBaseUrl}/teams/select?teamId=${teamId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: TeamApiResponse = await response.json();
      return data;
    },
    enabled: !!teamId,
  });
};

export default useSelectTeam;
