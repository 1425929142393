import { Badge } from '@fluency/ui/components/ui/badge';
import { Clock } from 'lucide-react';
import { useSubscription } from '@fluency/ui/hooks';
import { useNavigate } from '@tanstack/react-router';

const getDaysLeft = (trialEnd: string | number | Date) => {
  const now = new Date();
  const end = new Date(trialEnd);
  const diffTime = end.getTime() - now.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const TrialNotice = () => {
  const { subscriptionStatus } = useSubscription();
  const navigate = useNavigate();

  const isTrialing = subscriptionStatus?.status === 'trialing';
  const hasPaymentMethod = !!subscriptionStatus?.paymentMethod;
  const daysLeft = isTrialing
    ? subscriptionStatus?.subscription?.trialEnd
      ? getDaysLeft(subscriptionStatus.subscription.trialEnd)
      : 0
    : 0;

  if (!isTrialing || hasPaymentMethod) return null;

  return (
    <Badge
      variant="outline"
      className="items-center gap-3 flex text-gray-500 w-fit rounded border-0 hover:cursor-pointer px-2 mb-1"
      onClick={() => {
        navigate({
          to: '/profile/$setting',
          replace: true,
          params: { setting: 'subscription' },
        });
      }}
    >
      <Clock className="w-4 h-4 flex-shrink-0" />
      <div className="flex flex-col text-left">
        <div>{`Trial ends in ${daysLeft} ${
          daysLeft === 1 ? 'day' : 'days'
        } `}</div>
        <div className="font-normal text-xs">Subscribe to continue access</div>
      </div>
    </Badge>
  );
};

export default TrialNotice;
