import { useSubscription } from '@fluency/ui/hooks';
import { RefreshCw, User2 } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@fluency/ui/components/ui/card';
import { Progress } from '@fluency/ui/components/ui/progress';

export function Seats() {
  const { subscriptionStatus, isLoadingSubscription, subscriptionError } =
    useSubscription();

  const SeatCard = ({
    title,
    total,
    used,
    available,
  }: {
    title: string;
    total: number;
    used: number;
    available: number;
  }) => {
    const usagePercentage = (used / total) * 100;
    return (
      <Card className="w-full">
        <CardHeader className="pb-2 flex items-center justify-between flex-row">
          <CardTitle className="text-lg font-semibold flex items-center gap-2">
            <User2 className="w-5 h-5" />
            {title}
          </CardTitle>
          <p className="text-2xl font-semibold">
            {total}
            <span className="text-base font-normal text-muted-foreground ml-1">
              total
            </span>
          </p>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            <Progress value={usagePercentage} className="h-2" />
            <div className="flex justify-between text-sm text-gray-600">
              <span>{used} used</span>
              <span>{available} available</span>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  const renderContent = () => {
    if (isLoadingSubscription) {
      return (
        <div className="flex justify-center items-center h-48">
          <RefreshCw className="w-8 h-8 animate-spin text-gray-500" />
        </div>
      );
    }

    if (subscriptionError) {
      return (
        <p className="text-center text-destructive">
          An error occurred while fetching subscription information.
        </p>
      );
    }

    if (!subscriptionStatus) {
      return (
        <p className="text-center text-muted-foreground">
          No subscription information found.
        </p>
      );
    }

    const { usage } = subscriptionStatus;

    return (
      <div
        className={`grid gap-6 ${
          usage.subscribedSeats.viewer > 0 ? 'sm:grid-cols-2' : ''
        }`}
      >
        <SeatCard
          title="Creators"
          total={usage.subscribedSeats.creator}
          used={usage.occupiedSeats.creator}
          available={usage.availableSeats.creator}
        />
        {usage.subscribedSeats.viewer > 0 && (
          <SeatCard
            title="Viewers"
            total={usage.subscribedSeats.viewer}
            used={usage.occupiedSeats.viewer}
            available={usage.availableSeats.viewer}
          />
        )}
      </div>
    );
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-md font-medium">Usage</CardTitle>
        <CardDescription>
          View seat usage across your organisation.
        </CardDescription>
      </CardHeader>
      <CardContent>{renderContent()}</CardContent>
    </Card>
  );
}
