import { AnnotationType } from '../types/master.js';

interface AnnotationsProps {
  annotations: AnnotationType[];
  annotationVisible: boolean;
  cropData?: {
    x1: number | null;
    y1: number | null;
    x2: number | null;
    y2: number | null;
  };
}

const Annotation = ({
  annotations,
  annotationVisible,
  cropData,
}: AnnotationsProps) => {
  if (!annotationVisible) {
    return null;
  }

  // Helper function to adjust coordinates based on crop
  const adjustCoordinate = (coord: number, isX: boolean): number => {
    if (
      !cropData ||
      !cropData.x1 ||
      !cropData.y1 ||
      !cropData.x2 ||
      !cropData.y2
    ) {
      return coord;
    }

    const cropStart = isX ? cropData.x1 : cropData.y1;
    const cropEnd = isX ? cropData.x2 : cropData.y2;
    const cropSize = cropEnd - cropStart;

    // Convert coordinate from original image space to cropped space
    return ((coord - cropStart) / cropSize) * 100;
  };

  // Filter out annotations with coordinates (0, 0) and those outside crop area
  const validAnnotations = annotations.filter((annotation) => {
    if (annotation.x1Coordinate === 0 && annotation.y1Coordinate === 0) {
      return false;
    }

    if (
      cropData &&
      cropData.x1 !== null &&
      cropData.y1 !== null &&
      cropData.x2 !== null &&
      cropData.y2 !== null
    ) {
      // Check if annotation is within crop bounds
      const isStartInBounds =
        annotation.x1Coordinate >= cropData.x1 &&
        annotation.x1Coordinate <= cropData.x2 &&
        annotation.y1Coordinate >= cropData.y1 &&
        annotation.y1Coordinate <= cropData.y2;

      // For shapes that have end coordinates, check those too
      if (
        annotation.x2Coordinate !== null &&
        annotation.y2Coordinate !== null
      ) {
        const isEndInBounds =
          annotation.x2Coordinate >= cropData.x1 &&
          annotation.x2Coordinate <= cropData.x2 &&
          annotation.y2Coordinate >= cropData.y1 &&
          annotation.y2Coordinate <= cropData.y2;
        return isStartInBounds || isEndInBounds;
      }

      return isStartInBounds;
    }

    return true;
  });

  if (validAnnotations.length === 0) {
    return null;
  }

  const renderShape = (annotation: AnnotationType) => {
    const color = annotation.color || 'red';

    // For non-circle shapes, ensure we have all required coordinates
    if (
      annotation.shapeType !== 'CIRCLE' &&
      (annotation.x2Coordinate === null || annotation.y2Coordinate === null)
    ) {
      return null;
    }

    switch (annotation.shapeType) {
      case 'CIRCLE':
        return (
          <div
            className="absolute"
            style={{
              left: `${adjustCoordinate(annotation.x1Coordinate, true)}%`,
              top: `${adjustCoordinate(annotation.y1Coordinate, false)}%`,
              transform: 'translate(-50%, -50%)',
              overflow: 'visible',
            }}
          >
            <svg
              width="30"
              height="30"
              className="overflow-visible"
              style={{ overflow: 'visible' }}
            >
              <circle
                cx="15"
                cy="15"
                r="15"
                fill={color}
                fillOpacity="0.15"
                stroke={color}
                strokeWidth="2"
              />
            </svg>
          </div>
        );

      case 'RECTANGLE': {
        const x2 = annotation.x2Coordinate!;
        const y2 = annotation.y2Coordinate!;

        // Adjust both start and end coordinates
        const adjustedX1 = adjustCoordinate(annotation.x1Coordinate, true);
        const adjustedY1 = adjustCoordinate(annotation.y1Coordinate, false);
        const adjustedX2 = adjustCoordinate(x2, true);
        const adjustedY2 = adjustCoordinate(y2, false);

        const width = Math.abs(adjustedX2 - adjustedX1);
        const height = Math.abs(adjustedY2 - adjustedY1);
        const left = Math.min(adjustedX1, adjustedX2);
        const top = Math.min(adjustedY1, adjustedY2);

        return (
          <div
            className="absolute border-2"
            style={{
              left: `${left}%`,
              top: `${top}%`,
              width: `${width}%`,
              height: `${height}%`,
              borderColor: color,
            }}
          />
        );
      }

      case 'LINE':
      case 'ARROW': {
        const x2 = annotation.x2Coordinate!;
        const y2 = annotation.y2Coordinate!;

        // Adjust both start and end coordinates
        const adjustedX1 = adjustCoordinate(annotation.x1Coordinate, true);
        const adjustedY1 = adjustCoordinate(annotation.y1Coordinate, false);
        const adjustedX2 = adjustCoordinate(x2, true);
        const adjustedY2 = adjustCoordinate(y2, false);

        const id = `annotation-arrow-${annotation.screenshotAnnotationId}`;
        return (
          <svg className="absolute inset-0 w-full h-full overflow-visible">
            {annotation.shapeType === 'ARROW' && (
              <defs>
                <marker
                  id={id}
                  markerWidth="10"
                  markerHeight="7"
                  refX="9"
                  refY="3.5"
                  orient="auto"
                >
                  <polygon points="0 0, 10 3.5, 0 7" fill={color} />
                </marker>
              </defs>
            )}
            <line
              x1={`${adjustedX1}%`}
              y1={`${adjustedY1}%`}
              x2={`${adjustedX2}%`}
              y2={`${adjustedY2}%`}
              stroke={color}
              strokeWidth="2"
              markerEnd={
                annotation.shapeType === 'ARROW' ? `url(#${id})` : undefined
              }
              vectorEffect="non-scaling-stroke"
            />
          </svg>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className="absolute inset-0 pointer-events-none">
      {validAnnotations.map((annotation) => (
        <div
          key={annotation.screenshotAnnotationId}
          className="pointer-events-none"
        >
          {renderShape(annotation)}
        </div>
      ))}
    </div>
  );
};

export default Annotation;
