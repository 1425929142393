import { useNavigate } from '@tanstack/react-router';
import { FileText, Workflow } from 'lucide-react';
import {
  NotificationItem as NotificationItemType,
  NotificationItemType as NotificationType,
} from '../types/notifications.type';
import { useMarkNotificationAsRead } from '../hooks/useNotificationMutations';

interface NotificationItemProps {
  notification: NotificationItemType;
  onNotificationClick?: (notification: NotificationItemType) => void;
}

const ResourceIcon = ({ type }: { type: 'document' | 'workflow' }) => {
  switch (type) {
    case 'document':
      return <FileText className="h-4 w-4 text-primary" />;
    case 'workflow':
      return <Workflow className="h-4 w-4 text-primary" />;
  }
};

const Avatar = ({ name }: { name: string }) => {
  const initials = name
    .split(' ')
    .map((n) => n[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);

  return (
    <div className="w-8 h-8 rounded-full bg-[#E9ECF0] text-[#44546F] flex items-center justify-center text-sm font-medium">
      {initials}
    </div>
  );
};

export function NotificationItem({
  notification,
  onNotificationClick,
}: NotificationItemProps) {
  const navigate = useNavigate();
  const markAsRead = useMarkNotificationAsRead();

  const handleClick = async () => {
    if (!notification.read) {
      try {
        await markAsRead.mutateAsync(notification.id);
      } catch (error) {
        console.error('Failed to mark notification as read:', error);
      }
    }

    if (onNotificationClick) {
      onNotificationClick(notification);
    } else {
      navigate({
        to: notification.link as any,
        replace: false,
      });
    }
  };

  const getNotificationDescription = (): React.ReactNode => {
    const actorName = notification.actor.name;
    const resourceName = notification.resource.name;

    switch (notification.type) {
      case NotificationType.RESOURCE_REQUEST_ASSIGNED:
        return (
          <>
            <span className="font-medium">{actorName}</span> requested you to
            complete <span className="font-medium">{resourceName}</span>
          </>
        );
      case NotificationType.RESOURCE_REQUEST_UPDATED:
        return (
          <>
            <span className="font-medium">{actorName}</span> updated the request
            for <span className="font-medium">{resourceName}</span>
          </>
        );
      case NotificationType.RESOURCE_REQUEST_COMPLETED:
        return (
          <>
            <span className="font-medium">{actorName}</span> completed{' '}
            <span className="font-medium">{resourceName}</span>
          </>
        );
      case NotificationType.RESOURCE_REQUEST_CANCELLED:
        return (
          <>
            <span className="font-medium">{actorName}</span> cancelled the
            request for <span className="font-medium">{resourceName}</span>
          </>
        );
      case NotificationType.RESOURCE_REQUEST_OVERDUE:
        return (
          <>
            Request for <span className="font-medium">{resourceName}</span> is
            overdue
          </>
        );
      default:
        return '';
    }
  };

  return (
    <div
      onClick={handleClick}
      className="flex items-start gap-4 p-4 group hover:bg-[#F3F6F8] transition-colors cursor-pointer"
    >
      <Avatar name={notification.actor.name} />
      <div className="flex-1 min-w-0">
        <div className="text-sm leading-normal text-[#172B4D]">
          {getNotificationDescription()}
        </div>
        <div className="flex items-center gap-3 mt-2 text-xs text-[#44546F]">
          <span>{notification.vault.name}</span>
          <span>•</span>
          <span>{notification.time}</span>
        </div>
        <div className="flex items-center gap-2 mt-2 p-1.5 border border-[#DFE1E6] rounded-md w-fit group-hover:border-[#C1C7D0] transition-colors">
          <ResourceIcon type={notification.resource.type} />
          <span className="text-xs text-primary">
            {notification.resource.name}
          </span>
        </div>
      </div>
      {!notification.read && (
        <div className="w-2 h-2 rounded-full bg-fluency-500 mt-2 flex-shrink-0" />
      )}
    </div>
  );
}
