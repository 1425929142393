import { Badge } from '@fluency/ui/components/ui/badge';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import { useParams } from '@tanstack/react-router';
import { CalendarDays, Clock, FileText, RefreshCw, User2 } from 'lucide-react';
import { useGetPublicDocs } from '../hooks/index.js';
import type {
  Screenshot,
  SharedDocument,
  Step,
} from '../hooks/useGetPublicDocs.js';
import { getStepTypeIcon, stepTypeColors } from './iconHelper.js';
import ErrorMessage from './Error.js';
import PublicScreenshotViewer from './Screenshot.js';

const Loading: React.FC = () => (
  <div className="flex justify-center items-center mx-auto max-w-7xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 h-[60vh]">
    <RefreshCw className="h-10 w-10 animate-spin text-gray-300" />
  </div>
);

interface DocumentCardProps {
  step: Step;
  index: number;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ step, index }) => {
  const screenshot: Screenshot | undefined = step.screenshots[0];
  const stepTypeClass =
    stepTypeColors[step.stepType as keyof typeof stepTypeColors] || '';
  const isStepTypeStep = step.stepType === 'STEP';

  const formatStepType = (stepType: string) => {
    if (!stepType) return '';
    return stepType.charAt(0).toUpperCase() + stepType.slice(1).toLowerCase();
  };

  return (
    <div
      className={`mb-6 ${stepTypeClass} ${
        !isStepTypeStep ? 'p-4 rounded-xl' : ''
      }`}
    >
      <p className="mb-2 flex flex-col gap-1">
        <div className="flex items-center">
          {getStepTypeIcon(step.stepType)}
          <b className="whitespace-nowrap font-semibold">
            {isStepTypeStep
              ? `Step ${index}: `
              : `${formatStepType(step.stepType)}: `}
          </b>
        </div>
        {step.stepDescription}
      </p>
      {screenshot?.downloadUrl && (
        <PublicScreenshotViewer screenshot={screenshot} />
      )}
    </div>
  );
};

const Documents: React.FC = () => {
  const { document } = useParams({ from: '/share/$document' });
  const { data, isLoading, isError } = useGetPublicDocs(document);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <ErrorMessage />;
  }
  if (!data || !data.sharedDocument) {
    return null;
  }

  const sharedDocument: SharedDocument = data.sharedDocument;

  const formattedCreatedDate = sharedDocument.createdDate
    ? new Date(sharedDocument.createdDate).toISOString()
    : '';

  const formatDuration = (duration: number | string) => {
    const durationStr =
      typeof duration === 'number' ? duration.toString() : duration;
    const parts = durationStr.split('.');
    const minutes = parts[0];
    const seconds =
      parts.length > 1
        ? Math.round(parseFloat(`0.${parts[1]}`) * 60).toString()
        : '0';
    return `${minutes} min ${seconds} sec`;
  };

  const formatDate = (dateString: string | number | Date) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const stepTypeSteps = sharedDocument.steps.filter(
    (step) => step.stepType === 'STEP'
  );

  return (
    <div className="max-w-5xl min-w-[200px] mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle>{sharedDocument.documentationName}</CardTitle>
          <CardDescription className="text-lg">
            {sharedDocument.description}
            <div className="flex flex-wrap gap-x-2 gap-y-3 mt-6 w-full">
              <Badge variant="secondary">
                <FileText className="h-4 w-4 my-0.5 mr-2" />
                {sharedDocument.totalSteps} Steps
              </Badge>
              <Badge variant="secondary">
                <User2 className="h-4 w-4 my-0.5 mr-2" />
                {sharedDocument.userInfo[0]?.firstName
                  ? `${sharedDocument.userInfo[0].firstName} ${
                      sharedDocument.userInfo[0].lastName?.[0] || ''
                    }`
                  : sharedDocument.userInfo[0]?.email || 'Unknown'}
              </Badge>
              <Badge variant="secondary">
                <Clock className="h-4 w-4 my-0.5 mr-2" />
                {sharedDocument.duration &&
                  formatDuration(sharedDocument.duration)}
              </Badge>
              <Badge variant="secondary">
                <CalendarDays className="h-4 w-4 my-0.5 mr-2" />
                {formattedCreatedDate && (
                  <>
                    <p className="mr-1">Created on</p>
                    <time dateTime={formattedCreatedDate}>
                      {sharedDocument.createdDate &&
                        formatDate(sharedDocument.createdDate)}
                    </time>
                  </>
                )}
              </Badge>
            </div>
          </CardDescription>
        </CardHeader>
        <CardContent>
          {sharedDocument.steps.map((step, _index) => {
            const stepIndex =
              stepTypeSteps.findIndex((s) => s.stepId === step.stepId) + 1;
            return (
              <DocumentCard
                key={step.stepId}
                step={step}
                index={step.stepType === 'STEP' ? stepIndex : 0}
              />
            );
          })}
        </CardContent>
      </Card>
    </div>
  );
};

export default Documents;
