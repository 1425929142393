import { LockIcon } from 'lucide-react';
import { Button } from '@fluency/ui/components/ui/button';

export default function ErrorMessage() {
  const handleContactSupport = () => {
    window.location.href = 'mailto:support@getfluency.com.au';
  };

  return (
    <div className="flex flex-col justify-center items-center mx-auto max-w-7xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-24 space-y-6 h-[60vh]">
      <LockIcon className="h-16 w-16  text-gray-500" />
      <div className="text-center space-y-3">
        <h2 className="text-lg font-semibold">
          This document is not publicly shared
        </h2>
        <p className="text-sm text-gray-600 max-w-md">
          The owner needs to enable public sharing for this document.
          <br /> If you believe this is a mistake, please contact our support
          team.
        </p>
      </div>
      <Button onClick={handleContactSupport} variant="ghost">
        support@getfluency.com.au
      </Button>
    </div>
  );
}
