import { SubscriptionStatus } from '@fluency/ui/hooks';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../../auth/AuthProvider';
import { getSubscriptionStatus } from '../requests/getSubscriptionStatus';

export const useGetSubscriptionStatus = () => {
  const { accessToken } = useAuth();

  return useQuery<SubscriptionStatus, Error>({
    queryKey: ['subscriptionStatus'],
    queryFn: async () => {
      if (!accessToken) {
        throw new Error('Access token is missing');
      }
      const subscriptionStatusResponse = await getSubscriptionStatus(
        accessToken
      );

      return subscriptionStatusResponse;
    },
  });
};
