import {
  ApiNotificationResponse,
  NotificationQueryParams,
  NotificationResponse,
} from '../types/notifications.type';
import { transformApiResponse } from '../util/notification.util';

const API_BASE_URL = import.meta.env.VITE_SERVER_API_URL;

export async function fetchNotifications(
  accessToken: string,
  params: NotificationQueryParams
): Promise<NotificationResponse> {
  try {
    if (!accessToken) {
      throw new Error('No access token provided');
    }

    const queryParams = new URLSearchParams({
      page: (params.page || 1).toString(),
      limit: (params.limit || 10).toString(),
    });

    console.log('Fetching notifications with params:', {
      url: `${API_BASE_URL}/notifications?${queryParams}`,
      hasToken: !!accessToken,
      params,
    });

    const response = await fetch(
      `${API_BASE_URL}/notifications?${queryParams}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Notification API Error:', {
        status: response.status,
        statusText: response.statusText,
        body: errorText,
      });
      throw new Error(`API Error: ${response.statusText}`);
    }

    const apiResponse: ApiNotificationResponse = await response.json();

    // Log the raw response for debugging
    console.log('Raw API Response:', apiResponse);

    if (!apiResponse) {
      throw new Error('Empty response received from API');
    }

    const transformedResponse = transformApiResponse(apiResponse);

    // Log the transformed response for debugging
    console.log('Transformed Response:', transformedResponse);

    return transformedResponse;
  } catch (error) {
    console.error('Fetch notifications error:', error);
    throw error;
  }
}
