import { Switch } from '@fluency/ui/components/ui/switch';
import { useParams } from '@tanstack/react-router';
import { useScreenshotAnnotation } from '@fluency/ui/features/ViewDocument/hooks';
import ColorPicker from '../ColorPicker';
import { ScreenshotType } from '@fluency/ui/features/ViewDocument/types/master';
import ShapeSelector from './ShapeSelector';

interface AnnotationSettingsProps {
  screenshot: ScreenshotType;
}

export const AnnotationSettings = ({ screenshot }: AnnotationSettingsProps) => {
  const { id: docId } = useParams({
    from: '/document/$id',
  });

  const { createAnnotation, updateScreenshot } = useScreenshotAnnotation({
    documentationId: docId,
  });

  const handleVisibilityChange = async () => {
    const newAnnotationState = !screenshot.annotation;

    // If enabling annotations and none exist, create a default one
    if (newAnnotationState && !screenshot.annotations.length) {
      await createAnnotation([
        {
          screenshotId: screenshot.screenshotId,
          shapeType: 'CIRCLE',
          color: '#FF0000',
          firstCoordinates: {
            annotationX1: 50,
            annotationY1: 50,
          },
        },
      ]);
    }

    updateScreenshot({
      screenshotsToUpdate: [
        {
          screenshotId: screenshot.screenshotId,
          annotation: newAnnotationState,
        },
      ],
    });
  };

  const handleColorChange = (newColor: string) => {
    if (!screenshot.annotations.length) {
      // Create new annotation if none exists
      createAnnotation([
        {
          screenshotId: screenshot.screenshotId,
          shapeType: 'CIRCLE',
          color: newColor,
          firstCoordinates: {
            annotationX1: 50,
            annotationY1: 50,
          },
        },
      ]);
    } else {
      {
        screenshot.annotations[0] &&
          updateScreenshot({
            screenshotsToUpdate: [
              {
                screenshotId: screenshot.screenshotId,
                annotations: [
                  {
                    annotationId:
                      screenshot.annotations[0].screenshotAnnotationId,
                    color: newColor,
                  },
                ],
              },
            ],
          });
      }
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2">
        <Switch
          checked={screenshot.annotation}
          onCheckedChange={handleVisibilityChange}
        />
        <label className="text-sm font-medium">Show Annotation</label>
      </div>
      <ShapeSelector screenshot={screenshot} />
      <div className="space-y-2">
        <label className="text-sm font-medium">Annotation Color</label>
        <ColorPicker
          initialColor={screenshot.annotations[0]?.color || '#FF0000'}
          onColorChange={handleColorChange}
        />
      </div>
    </div>
  );
};

export default AnnotationSettings;
