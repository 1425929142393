import React, { useRef, useState, useEffect } from 'react';
import { CropOverlay } from './overlays/CropOverlay';
import { RedactionOverlay } from './overlays/RedactionOverlay';
import { AnnotationOverlay } from './overlays/AnnotationOverlay';
import { CropData } from '../types';
import { RedactionRectangle } from '../types';
import { ScreenshotType } from '@fluency/ui/features/ViewDocument/types/master';

interface CanvasProps {
  screenshot: ScreenshotType;
  documentationId: string;
  activeFeature: string | null;
  cropData: CropData | null;
  aspectRatio: number | null;
  onCropChange: (newCrop: CropData) => void;
  redactionRectangles: RedactionRectangle[];
  onRedactionRectanglesChange: (rectangles: RedactionRectangle[]) => void;
  selectedRectangles: number[];
  onSelectedRectanglesChange: (selected: number[]) => void;
  currentRedactionColor: string;
}

export const Canvas: React.FC<CanvasProps> = ({
  screenshot,
  documentationId,
  activeFeature,
  cropData,
  aspectRatio,
  onCropChange,
  redactionRectangles,
  onRedactionRectanglesChange,
  selectedRectangles,
  onSelectedRectanglesChange,
  currentRedactionColor,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const updateDimensions = () => {
      if (!imageRef.current || !containerRef.current) return;

      const image = imageRef.current;
      const modalPadding = 48; // 24px padding on each side
      const sidebarWidth = 320; // Approximate width of the sidebar

      // Calculate available width (modal width - sidebar - padding)
      const modalWidth = window.innerWidth * 0.9; // 90vw from DialogContent
      const availableWidth = modalWidth - sidebarWidth - modalPadding;

      // Calculate available height
      const modalHeight = window.innerHeight * 0.9 - 80; // 90vh minus header height
      const availableHeight = modalHeight - modalPadding;

      // Calculate scale while maintaining aspect ratio
      const scale = Math.min(
        availableWidth / image.naturalWidth,
        availableHeight / image.naturalHeight
      );

      // Set scaled dimensions
      const scaledWidth = Math.floor(image.naturalWidth * scale);
      const scaledHeight = Math.floor(image.naturalHeight * scale);

      setDimensions({
        width: scaledWidth,
        height: scaledHeight,
      });
      setLoading(false);
    };

    const handleImageLoad = () => {
      updateDimensions();
    };

    // Update dimensions when window resizes
    window.addEventListener('resize', updateDimensions);

    // Handle image load
    if (imageRef.current) {
      imageRef.current.addEventListener('load', handleImageLoad);
      if (imageRef.current.complete) {
        handleImageLoad();
      }
    }

    return () => {
      window.removeEventListener('resize', updateDimensions);
      if (imageRef.current) {
        imageRef.current.removeEventListener('load', handleImageLoad);
      }
    };
  }, []);

  return (
    <div ref={containerRef} className="flex-1 p-6 pt-0 h-full overflow-auto">
      <div className="relative min-h-full flex items-start justify-center">
        <div
          className="relative border-gray-600 border bg-white"
          style={{
            width: dimensions.width,
            height: dimensions.height,
          }}
        >
          <img
            ref={imageRef}
            src={screenshot.downloadUrl}
            alt="Screenshot"
            className="w-full h-full object-contain"
            style={{
              opacity: loading ? 0 : 1,
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />

          {!loading && activeFeature === 'crop' && cropData && (
            <CropOverlay
              imageRef={imageRef}
              cropData={cropData}
              aspectRatio={aspectRatio}
              onCropChange={onCropChange}
              containerDimensions={dimensions}
            />
          )}

          {!loading && activeFeature === 'redact' && (
            <RedactionOverlay
              imageRef={imageRef}
              rectangles={redactionRectangles}
              onRectanglesChange={onRedactionRectanglesChange}
              selectedRectangles={selectedRectangles}
              onSelectedRectanglesChange={onSelectedRectanglesChange}
              currentColor={currentRedactionColor}
            />
          )}

          {!loading && activeFeature === 'annotate' && (
            <AnnotationOverlay
              imageRef={imageRef}
              screenshot={screenshot}
              documentationId={documentationId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Canvas;
