import {
  ToggleGroup,
  ToggleGroupItem,
} from '@fluency/ui/components/ui/toggle-group';
import { Circle, Square, Minus, ArrowRight } from 'lucide-react';
import { useParams } from '@tanstack/react-router';
import { useScreenshotAnnotation } from '@fluency/ui/features/ViewDocument/hooks';
import { ScreenshotType } from '@fluency/ui/features/ViewDocument/types/master';

type ShapeType = 'CIRCLE' | 'RECTANGLE' | 'LINE' | 'ARROW';

interface ShapeSelectorProps {
  screenshot: ScreenshotType;
}

export const ShapeSelector = ({ screenshot }: ShapeSelectorProps) => {
  const { id: docId } = useParams({
    from: '/document/$id',
  });

  const { createAnnotation, updateScreenshot } = useScreenshotAnnotation({
    documentationId: docId,
  });

  const handleShapeChange = (shape: ShapeType) => {
    if (!screenshot.annotations.length) {
      // Create new annotation if none exists
      createAnnotation([
        {
          screenshotId: screenshot.screenshotId,
          shapeType: 'CIRCLE',
          color: '#FF0000', // Default color
          firstCoordinates: {
            annotationX1: 50, // Default center position
            annotationY1: 50,
          },
        },
      ]);
    } else {
      {
        screenshot.annotations[0] &&
          updateScreenshot({
            screenshotsToUpdate: [
              {
                screenshotId: screenshot.screenshotId,
                annotations: [
                  {
                    annotationId:
                      screenshot.annotations[0].screenshotAnnotationId,
                    shapeType: shape,
                    x1Coordinate: screenshot.annotations[0]?.x1Coordinate || 50,
                    y1Coordinate: screenshot.annotations[0]?.y1Coordinate || 50,
                    x2Coordinate: screenshot.annotations[0]?.x2Coordinate || 80,
                    y2Coordinate: screenshot.annotations[0]?.y1Coordinate || 80,
                  },
                ],
              },
            ],
          });
      }
    }
  };

  return (
    <div className="space-y-2">
      <label className="text-sm font-medium">Shape Type</label>
      <ToggleGroup
        type="single"
        value={screenshot.annotations[0]?.shapeType || 'CIRCLE'}
        onValueChange={(value: ShapeType) => handleShapeChange(value)}
        className="justify-start"
      >
        <ToggleGroupItem value="CIRCLE" aria-label="Circle">
          <Circle className="h-4 w-4" />
        </ToggleGroupItem>
        <ToggleGroupItem value="RECTANGLE" aria-label="Rectangle">
          <Square className="h-4 w-4" />
        </ToggleGroupItem>
        <ToggleGroupItem value="LINE" aria-label="Line">
          <Minus className="h-4 w-4" />
        </ToggleGroupItem>
        <ToggleGroupItem value="ARROW" aria-label="Arrow">
          <ArrowRight className="h-4 w-4" />
        </ToggleGroupItem>
      </ToggleGroup>
    </div>
  );
};

export default ShapeSelector;
