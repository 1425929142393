import * as Popover from '@radix-ui/react-popover';
import { Bell, ExternalLink } from 'lucide-react';
import { Button } from '@fluency/ui/components/ui/button';
import { ScrollArea } from '@fluency/ui/components/ui/scroll-area';
import { Badge } from '@fluency/ui/components/ui/badge';
import { useNavigate } from '@tanstack/react-router';
import {
  usePopupNotifications,
  useUnreadNotificationsCount,
} from '../hooks/useNotifications';
import { useMarkAllNotificationsAsRead } from '../hooks/useNotificationMutations';
import { NotificationItem } from './NotificationItem';
import { NotificationItem as NotificationItemType } from '../types/notifications.type';
import { NotificationSkeleton } from './NotificationSkeleton';
import { ErrorState } from './ErrorState';

interface NotificationsProps {
  isActive: boolean;
  maxHeight?: string;
}

export default function NotificationPopup({
  isActive,
  maxHeight = '560px',
}: NotificationsProps) {
  const navigate = useNavigate();
  const markAllAsRead = useMarkAllNotificationsAsRead();
  const { data, isLoading, isError, refetch } = usePopupNotifications();
  const unreadCount = useUnreadNotificationsCount();

  const notifications = data?.data || [];

  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead.mutateAsync();
    } catch (error) {
      console.error('Failed to mark all notifications as read:', error);
    }
  };

  const handleNotificationClick = (notification: NotificationItemType) => {
    navigate({
      to: notification.link as any,
      replace: false,
    });
  };

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          className={`w-full flex items-center gap-2 text-sm rounded-lg px-2 py-1.5 cursor-pointer hover:bg-[#F3F6F8] ${
            isActive ? 'bg-[#F3F6F8] text-primary font-medium' : ''
          }`}
        >
          <Bell className="h-4 w-4" />
          <span>Notifications</span>
          {unreadCount > 0 && (
            <Badge
              variant="destructive"
              className="ml-auto h-2 w-2 flex items-center justify-center p-0 text-xs bg-[#CA3521]"
            />
          )}
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <div className="relative z-[9999]">
          <Popover.Content
            className="w-[400px] rounded-md ml-6 border border-[#DFE1E6] bg-white shadow-lg animate-in fade-in-0 zoom-in-95"
            side="right"
            align="center"
            sideOffset={5}
            alignOffset={0}
            collisionPadding={16}
            avoidCollisions
          >
            <div
              role="dialog"
              aria-label="Notifications"
              className="outline-none"
            >
              <div className="flex items-center justify-between px-3 py-2 border-b border-[#DFE1E6]">
                <div className="flex items-center gap-3">
                  <h2 className="text-[14px] text-[#172B4D] font-medium">
                    Notifications {unreadCount > 0 ? `(${unreadCount})` : ''}
                  </h2>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    className="text-xs hover:bg-[#F3F6F8]"
                    onClick={handleMarkAllAsRead}
                  >
                    Mark all as read
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="text-xs hover:bg-[#F3F6F8]"
                    onClick={() =>
                      navigate({ to: '/notifications', replace: true })
                    }
                  >
                    View all
                  </Button>
                </div>
              </div>

              <ScrollArea
                className="overflow-y-auto"
                style={{ height: maxHeight }}
              >
                {isLoading ? (
                  <div style={{ height: maxHeight }}>
                    <NotificationSkeleton />
                    <NotificationSkeleton />
                    <NotificationSkeleton />
                  </div>
                ) : isError ? (
                  <ErrorState onRetry={() => refetch()} />
                ) : notifications.length === 0 ? (
                  <div className="flex flex-col items-center justify-center py-8 text-[#44546F]">
                    <p className="text-sm">No notifications</p>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    {notifications.map((notification) => (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        onNotificationClick={handleNotificationClick}
                      />
                    ))}

                    <div className="text-center py-6 border-t border-[#DFE1E6]">
                      {/* <p className="text-[13px] text-[#44546F] mb-2">
                        That's all your recent notifications.
                      </p> */}
                      <Button
                        variant="ghost"
                        size="sm"
                        className="text-xs hover:bg-[#F3F6F8]"
                        onClick={() =>
                          navigate({ to: '/notifications', replace: true })
                        }
                      >
                        View all notifications{' '}
                        <ExternalLink className="h-3 w-3 ml-1" />
                      </Button>
                    </div>
                  </div>
                )}
              </ScrollArea>
            </div>
          </Popover.Content>
        </div>
      </Popover.Portal>
    </Popover.Root>
  );
}
