import { useSubscription } from '@fluency/ui/hooks';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { RefreshCw } from 'lucide-react';
import { DisplayName } from './DisplayName';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import { Separator } from '@fluency/ui/components/ui/separator';
import { Label } from '@fluency/ui/components/ui/label';
import { Button } from '@fluency/ui/components/ui/button';

export const GeneralSettings = () => {
  const { user, orgId, isLoading, organisationName, role } = useAuth();
  const { subscriptionStatus } = useSubscription();

  const formatSubscriptionStatus = (status: string): string => {
    if (status === 'trialing') return 'Pro (free trial)';
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  if (isLoading) {
    return (
      <div className="h-[200px] flex items-center justify-center">
        <RefreshCw className="w-6 h-6 animate-spin text-muted-foreground" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium mb-1">General Profile</h2>
        <p className="text-sm text-muted-foreground">
          View and manage your account details
        </p>
      </div>

      <Card className="rounded-lg border">
        <CardHeader className="pb-3">
          <CardTitle className="text-base font-medium">
            Account Information
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <DisplayName user={user} />
          <Separator />
          <div className="grid gap-1">
            <div className="flex items-center">
              <Label>Email address</Label>
              {user?.emailVerified ? (
                <span className="ml-2 text-xs bg-fluency-600 text-white py-0.5 px-2 rounded-full">
                  Verified
                </span>
              ) : (
                <span className="ml-2 text-xs bg-yellow-100 text-yellow-700 py-0.5 px-2 rounded-full">
                  Not verified
                </span>
              )}
            </div>
            <p className="text-sm text-muted-foreground">{user?.email}</p>
          </div>
        </CardContent>
      </Card>

      {orgId && (
        <Card className="rounded-lg border">
          <CardHeader className="pb-3">
            <CardTitle className="text-base font-medium">
              Membership Details
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="grid gap-1">
              <Label>Organisation Name</Label>
              <div className="flex items-center justify-between">
                <p className="text-sm text-muted-foreground">
                  {organisationName}
                </p>
                <Button variant="outline" size="sm" disabled>
                  Managed by admin
                </Button>
              </div>
            </div>
            <Separator />
            {subscriptionStatus?.status && (
              <div className="grid gap-1">
                <Label>License Status</Label>
                <div className="flex items-center justify-between">
                  <p className="text-sm text-muted-foreground">
                    {formatSubscriptionStatus(subscriptionStatus.status)}
                  </p>
                  <Button variant="outline" size="sm" disabled>
                    Managed by admin
                  </Button>
                </div>
              </div>
            )}
            <Separator />
            {role && (
              <div className="grid gap-1">
                <Label>Role</Label>
                <div className="flex items-center justify-between">
                  <p className="text-sm text-muted-foreground capitalize">
                    {role}
                  </p>
                  <Button variant="outline" size="sm" disabled>
                    Managed by admin
                  </Button>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default GeneralSettings;
