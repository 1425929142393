import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { NaturalSize } from '../types';

interface LoadingStateProps {
  naturalSize: NaturalSize | null;
}

export const LoadingState: React.FC<LoadingStateProps> = ({ naturalSize }) => (
  <div
    className="bg-slate-100 flex items-center justify-center"
    style={{
      width: naturalSize?.width,
      height: naturalSize?.height,
    }}
  >
    <div className="text-gray-500 font-medium flex gap-2 items-center">
      <ArrowPathIcon className="w-5 h-5 animate-spin" />
      Updating screenshot...
    </div>
  </div>
);
