import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from '../components/ui/use-toast.js';
import { useAuth } from '../providers/auth/AuthProvider.js';
import { ProrationResponse } from './types/AdjustSeats.js';
import {
  BillingDetails,
  PortalResponse,
  SubscriptionRequest,
  SubscriptionStatus,
} from './types/index.js';

export const useSubscription = () => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  const subscriptionQuery = useQuery<SubscriptionStatus, Error>({
    queryKey: ['subscriptionStatus'],
    queryFn: async (): Promise<SubscriptionStatus> => {
      if (!accessToken) {
        throw new Error('Access token is missing');
      }

      const response = await fetch(
        `${apiBaseUrl}/subscription/getSubscriptionStatus`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch subscription status');
      }

      const data: SubscriptionStatus = await response.json();
      return data;
    },
  });

  const billingQuery = useQuery<BillingDetails, Error>({
    queryKey: ['billingDetails'],
    queryFn: async (): Promise<BillingDetails> => {
      if (!accessToken) {
        throw new Error('Access token is missing');
      }

      const response = await fetch(`${apiBaseUrl}/billing`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch billing details');
      }

      const data: BillingDetails = await response.json();
      return data;
    },
  });

  const removePaymentMethodMutation = useMutation<void, Error, string>({
    mutationFn: async (paymentMethodId: string) => {
      if (!accessToken) {
        throw new Error('Access token is missing');
      }

      const endpoint = `${apiBaseUrl}/billing/paymentMethod/${paymentMethodId}`;
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('Payment method removed successfully');
    },
    onSuccess: () => {
      // Invalidate and refetch relevant queries after removing payment method
      queryClient.invalidateQueries({ queryKey: ['subscriptionStatus'] });
      queryClient.invalidateQueries({ queryKey: ['billingDetails'] });
    },
    onError: () => {
      toast({ title: 'Error', description: 'Failed to remove payment method' });
      Sentry.captureException('Failed to remove payment method');
    },
  });

  const customerPortalMutation = useMutation<
    PortalResponse,
    Error,
    SubscriptionRequest
  >({
    mutationFn: async (
      request: SubscriptionRequest
    ): Promise<PortalResponse> => {
      if (!accessToken) {
        throw new Error('Access token is missing');
      }

      const successUrl = `${window.location.origin}`;
      const cancelUrl = `${window.location.origin}`;

      const body = {
        product: (request.productName?.split(' ')[0] ?? '').toLowerCase(),
        plan: request.isAnnual ? 'annual' : 'monthly',
        creatorSeats: request.seats,
      };

      const response = await fetch(
        `${apiBaseUrl}/subscription/manage?successUrl=${successUrl}&cancelUrl=${cancelUrl}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: PortalResponse = await response.json();
      return data;
    },
    onError: () => {
      toast({ title: 'Error', description: 'Failed to open customer portal' });
      Sentry.captureException('Failed to open customer portal');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['subscriptionStatus'] });
      queryClient.invalidateQueries({ queryKey: ['billingDetails'] });
    },
  });

  const cancelSubscriptionMutation = useMutation<void, Error, void>({
    mutationFn: async () => {
      if (!accessToken) {
        throw new Error('Access token is missing');
      }

      const response = await fetch(`${apiBaseUrl}/subscription/cancel`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await response.text();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['subscriptionStatus'] });
      queryClient.invalidateQueries({ queryKey: ['billingDetails'] });
      toast({
        title: 'Success',
        description: 'Subscription canceled successfully',
      });
    },
    onError: () => {
      toast({ title: 'Error', description: 'Failed to cancel subscription' });
      Sentry.captureException('Failed to cancel subscription');
    },
  });

  const calculateProrationMutation = useMutation<
    ProrationResponse,
    Error,
    { creatorSeats: number; plan: 'monthly' | 'annual'; product: string }
  >({
    mutationFn: async ({ creatorSeats, plan, product }) => {
      if (!accessToken) {
        throw new Error('Access token is missing');
      }

      const urlencoded = new URLSearchParams();
      urlencoded.append('product', product);
      urlencoded.append('plan', plan);
      urlencoded.append('creatorSeats', creatorSeats.toString());
      // urlencoded.append('viewerSeats', '');

      const response = await fetch(
        `${apiBaseUrl}/subscription/previewProration`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: urlencoded,
        }
      );

      if (!response.ok) {
        const data = await response.json();
        console.error('Error calculating proration:', data);
        throw new Error('Failed to calculate proration');
      }

      const result: ProrationResponse = await response.json();
      return result;
    },
  });

  return {
    // Subscription status
    subscriptionStatus: subscriptionQuery.data,
    isLoadingSubscription: subscriptionQuery.isLoading,
    subscriptionError: subscriptionQuery.error,
    refetchSubscription: subscriptionQuery.refetch,

    // Billing
    billingDetails: billingQuery.data,
    isLoadingBilling: billingQuery.isLoading,
    billingError: billingQuery.error,
    refetchBilling: billingQuery.refetch,

    // Payment methods
    removePaymentMethod: removePaymentMethodMutation.mutate,
    isRemovingPaymentMethod: removePaymentMethodMutation.isPending,
    removePaymentMethodError: removePaymentMethodMutation.error,

    // Customer portal
    createCustomerPortalSession: customerPortalMutation.mutate,
    isCreatingCustomerPortalSession: customerPortalMutation.isPending,
    customerPortalError: customerPortalMutation.error,

    // Cancel subscription
    cancelSubscription: cancelSubscriptionMutation.mutate,
    isCancelingSubscription: cancelSubscriptionMutation.isPending,
    cancelSubscriptionError: cancelSubscriptionMutation.error,

    // Proration
    calculateProration: calculateProrationMutation.mutate,
    isCalculatingProration: calculateProrationMutation.isPending,
    prorationError: calculateProrationMutation.error,
  };
};

export default useSubscription;
