import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { TeamApiResponse, UsersInTeam } from '../types/types';
import { useGetOrgUsers } from '@fluency/ui/hooks/useGetOrgUsers';

interface AssociationResponse {
  message: string;
  success: boolean;
  associatedUsers?: {
    teamAssociationId: string;
    teamId: string;
    appUserId: string;
  }[];
}

interface AssociationParams {
  teamId: string;
  userIds: string[];
}

export const useTeamUserAssociation = () => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();
  const { data: orgUsersData } = useGetOrgUsers();

  const associateMutation = useMutation({
    mutationFn: async ({ teamId, userIds }: AssociationParams) => {
      const response = await fetch(
        `${import.meta.env.VITE_SERVER_API_URL}/teams/associateUsersToTeam`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ teamId, userIds }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(
          data.message || `HTTP error! status: ${response.status}`
        );
      }

      return data as AssociationResponse;
    },
    onMutate: async ({ teamId, userIds }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['team', teamId] });

      // Get current team data
      const currentTeam = queryClient.getQueryData<TeamApiResponse>([
        'team',
        teamId,
      ]);

      if (!currentTeam || !orgUsersData) return { previousTeam: currentTeam };

      // Create new users array matching UsersInTeam interface
      const newUsers: UsersInTeam[] = orgUsersData.memberships
        .filter((membership) => userIds.includes(membership.user.id))
        .map((membership) => ({
          userId: membership.user.id,
          email: membership.user.email,
          firstName: membership.user.firstName,
          lastName: membership.user.lastName,
        }));

      // Create optimistic update
      const optimisticTeam: TeamApiResponse = {
        ...currentTeam,
        usersInTeam: [...currentTeam.usersInTeam, ...newUsers],
      };

      // Update the cache
      queryClient.setQueryData(['team', teamId], optimisticTeam);

      return { previousTeam: currentTeam };
    },
    onError: (error, { teamId }, context) => {
      if (context?.previousTeam) {
        queryClient.setQueryData(['team', teamId], context.previousTeam);
      }

      toast({
        variant: 'destructive',
        title: 'Failed to add team member',
        description:
          error instanceof Error && error.message.includes('409')
            ? 'User is already a member of this team'
            : 'Failed to add team member. Please try again.',
      });
    },
    onSuccess: (_, { teamId }) => {
      queryClient.invalidateQueries({ queryKey: ['team', teamId] });
      toast({
        title: 'Success',
        description: 'Team member added successfully',
      });
    },
  });

  const disassociateMutation = useMutation({
    mutationFn: async ({ teamId, userIds }: AssociationParams) => {
      const response = await fetch(
        `${
          import.meta.env.VITE_SERVER_API_URL
        }/teams/disassociateUsersFromTeam`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ teamId, userIds }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(
          data.message || `HTTP error! status: ${response.status}`
        );
      }

      return data as AssociationResponse;
    },
    onMutate: async ({ teamId, userIds }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['team', teamId] });

      // Get current team data
      const currentTeam = queryClient.getQueryData<TeamApiResponse>([
        'team',
        teamId,
      ]);
      if (!currentTeam) return { previousTeam: currentTeam };

      // Create optimistic update
      const optimisticTeam = {
        ...currentTeam,
        usersInTeam: currentTeam.usersInTeam.filter(
          (user) => !userIds.includes(user.userId)
        ),
      };

      // Update the cache
      queryClient.setQueryData(['team', teamId], optimisticTeam);

      return { previousTeam: currentTeam };
    },
    onError: (error, { teamId }, context) => {
      // Revert to previous state on error
      if (context?.previousTeam) {
        queryClient.setQueryData(['team', teamId], context.previousTeam);
      }

      toast({
        variant: 'destructive',
        title: 'Failed to remove team member',
        description:
          error instanceof Error && error.message.includes('404')
            ? 'Team member not found'
            : 'Failed to remove team member. Please try again.',
      });
    },
    onSuccess: (_, { teamId }) => {
      queryClient.invalidateQueries({ queryKey: ['team', teamId] });
      toast({
        title: 'Success',
        description: 'Team member removed successfully',
      });
    },
  });

  return {
    associateUsersToTeam: associateMutation.mutate,
    disassociateUsersFromTeam: disassociateMutation.mutate,
    isAssociating: associateMutation.isPending,
    isDisassociating: disassociateMutation.isPending,
  };
};

export default useTeamUserAssociation;
