import { ImageIcon, LoaderCircle } from 'lucide-react';
import { useRef, useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { Input } from '@fluency/ui/components/ui/input';
import { Label } from '@fluency/ui/components/ui/label';
import useReplaceScreenshot from '../hooks/useReplaceScreenshot.js';
import convertToPng from './editor/utils/convertToPng.js';

interface ChangeScreenshotDialogProps {
  stepId: string;
  documentId: string;
}

const ChangeScreenshotDialog: React.FC<ChangeScreenshotDialogProps> = ({
  stepId,
  documentId,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { mutate: replaceScreenshot, isPending } =
    useReplaceScreenshot(documentId);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (file) {
      const pngFile = await convertToPng(file);
      replaceScreenshot(
        {
          file: pngFile,
          stepId,
          fileType: 'png',
        },
        {
          onSuccess: () => {
            setIsOpen(false); // Close dialog on success
            setFile(null); // Reset file state
            if (fileInputRef.current) {
              fileInputRef.current.value = ''; // Reset file input
            }
          },
        }
      );
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile =
      event.target.files && event.target.files[0]
        ? event.target.files[0]
        : null;
    setFile(newFile);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm" className="group">
          <ImageIcon className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={onSubmit}>
          <DialogHeader>
            <DialogTitle>Change Screenshot</DialogTitle>
            <DialogDescription>
              Select a new screenshot to upload.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="screenshot">Screenshot</Label>
              <Input
                id="screenshot"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setIsOpen(false)}
              disabled={isPending}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isPending || file === null}>
              {isPending ? (
                <LoaderCircle className="animate-spin w-4 h-4" />
              ) : (
                'Upload Screenshot'
              )}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeScreenshotDialog;
