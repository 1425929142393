import { ErrorBoundary } from '@fluency/ui/components';
import { Toaster } from '@fluency/ui/components/ui/toaster';
import '@fluency/ui/globals.css';
import {
  AuthProvider,
  useAuth,
} from '@fluency/ui/providers/auth/AuthProvider.tsx';
import { ElectronProvider } from '@fluency/ui/providers/electron/ElectronContext';
import { FeatureFlagProvider } from '@fluency/ui/providers/feature-flags/FeatureFlagProvider.tsx';
import {
  PermissionGateProvider,
  usePermissionGate,
} from '@fluency/ui/providers/permissions/PermissionGateProvider.tsx';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { routeTree } from './routeTree.gen.ts';
import { RouterContext } from './types/RouterContext.ts';

const environment = import.meta.env.NODE_ENV;

Sentry.init({
  dsn: 'https://bb86cc243e255a72cc7a5019405a6457@o4505582792998912.ingest.us.sentry.io/4507740828991488',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.6,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/flueey\.com/,
    /^https:\/\/app\.getfluency\.com\.au/,
  ],
  profilesSampleRate: 0.5,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: environment,
});

// Create the router instance with the correct context type
const router = createRouter({
  routeTree,
  context: {} as RouterContext,
  trailingSlash: 'never',
});

// Declare the router type for TypeScript
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function ContextWrapper() {
  const auth = useAuth();
  const permissionGate = usePermissionGate();
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <FeatureFlagProvider>
        <RouterProvider
          router={router}
          context={{
            auth,
            permissionGate,
          }}
        />
      </FeatureFlagProvider>
    </QueryClientProvider>
  );
}

const rootElement = document.getElementById('root')!;

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <StrictMode>
      <ErrorBoundary>
        <ElectronProvider isElectron={false}>
          <AuthProvider>
            <PermissionGateProvider>
              <ContextWrapper />
              <Toaster />
            </PermissionGateProvider>
          </AuthProvider>
        </ElectronProvider>
      </ErrorBoundary>
    </StrictMode>
  );
}
