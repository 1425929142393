import { Button } from '@fluency/ui/components/ui/button';

import { Alert, AlertDescription } from '@fluency/ui/components/ui/alert';
import { Badge } from '@fluency/ui/components/ui/badge';
import { Checkbox } from '@fluency/ui/components/ui/checkbox';
import { Label } from '@fluency/ui/components/ui/label';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@fluency/ui/components/ui/table';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { AlertCircle, Sparkle } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PiiRedactedEntities } from '../../types/types';
import { useGetOrgPiiEntities } from './hooks/useGetOrgPiiEntities';
import { useUpdatePiiRedactionProperties } from './hooks/useUpdatePiiRedactionProperties';

const piiCategories = {
  general: [
    'CREDIT_CARD',
    'CRYPTO',
    'DATE_TIME',
    'EMAIL_ADDRESS',
    'IBAN_CODE',
    'IP_ADDRESS',
    'LOCATION',
    'MEDICAL_LICENSE',
    'NRP',
    'PERSON',
    'PHONE_NUMBER',
    'URL',
  ],
  au: ['AU_ABN', 'AU_ACN', 'AU_MEDICARE', 'AU_TFN'],
  us: [
    'UK_NHS',
    'US_BANK_NUMBER',
    'US_DRIVER_LICENSE',
    'US_ITIN',
    'US_PASSPORT',
    'US_SSN',
  ],
} as const;

type PiiCategory = keyof typeof piiCategories;

const piiCategoryDisplayNames: Record<string, string> = {
  CREDIT_CARD: 'Credit Card',
  CRYPTO: 'Cryptocurrency',
  DATE_TIME: 'Date and Time',
  EMAIL_ADDRESS: 'Email Address',
  IBAN_CODE: 'IBAN Code',
  IP_ADDRESS: 'IP Address',
  NRP: 'Nationality, Religion, Political Affiliation',
  LOCATION: 'Location',
  PERSON: 'Person',
  PHONE_NUMBER: 'Phone Number',
  MEDICAL_LICENSE: 'Medical License',
  URL: 'URL',
  US_BANK_NUMBER: 'US Bank Number',
  US_DRIVER_LICENSE: 'US Driver License',
  US_ITIN: 'US ITIN',
  US_PASSPORT: 'US Passport',
  US_SSN: 'US Social Security Number (SSN)',
  UK_NHS: 'UK NHS Number',
  AU_ABN: 'Australian Business Number (ABN)',
  AU_ACN: 'Australian Company Number (ACN)',
  AU_TFN: 'Australian Tax File Number (TFN)',
  AU_MEDICARE: 'Australian Medicare Number',
};

function LoadingTable() {
  return (
    <div className="animate-pulse">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>
              <div className="h-4 bg-gray-200 rounded w-20"></div>
            </TableHead>
            <TableHead>
              <div className="h-4 bg-gray-200 rounded w-20"></div>
            </TableHead>
            <TableHead>
              <div className="h-4 bg-gray-200 rounded w-20"></div>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Array.from({ length: 6 }, (_, i) => (
            <TableRow key={i}>
              {Array.from({ length: 3 }, (_, j) => (
                <TableCell key={j}>
                  <div className="flex items-center space-x-2">
                    <div className="h-4 w-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded w-32"></div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function PIITable() {
  const { data: fetchedPiiData, isLoading, error } = useGetOrgPiiEntities();
  const updatePiiMutation = useUpdatePiiRedactionProperties();
  const { toast } = useToast();
  const [piiData, setPiiData] = useState<PiiRedactedEntities | undefined>(
    undefined
  );
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (fetchedPiiData) {
      setPiiData(fetchedPiiData);
    }
  }, [fetchedPiiData]);

  const handleCheckboxChange = useCallback((key: keyof PiiRedactedEntities) => {
    setPiiData((prev) => {
      if (!prev) return prev;
      const updatedData = { ...prev, [key]: !prev[key] };
      setHasChanges(true);
      return updatedData;
    });
  }, []);

  const handleSave = useCallback(() => {
    if (!piiData) return;

    updatePiiMutation.mutate(piiData, {
      onSuccess: () => {
        toast({
          title: 'Success',
          description: 'PII entity settings updated successfully.',
          duration: 3000,
        });
        setHasChanges(false);
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'Failed to update PII settings. Please try again.',
          variant: 'destructive',
          duration: 3000,
        });
      },
    });
  }, [piiData, updatePiiMutation, toast]);

  const renderPIIRows = useCallback(
    (category: PiiCategory) => {
      return piiCategories[category].map((key) => (
        <TableRow key={key}>
          <TableCell className="p-0">
            <div className="flex items-center space-x-2">
              <Checkbox
                id={key}
                checked={piiData?.[key as keyof PiiRedactedEntities]}
                onCheckedChange={() =>
                  handleCheckboxChange(key as keyof PiiRedactedEntities)
                }
              />
              <Label htmlFor={key} className="text-sm font-medium leading-none">
                {piiCategoryDisplayNames[key] || key.replace(/_/g, ' ')}
              </Label>
            </div>
          </TableCell>
        </TableRow>
      ));
    },
    [piiData, handleCheckboxChange]
  );

  const { generalRows, usRows, auRows } = useMemo(
    () => ({
      generalRows: renderPIIRows('general'),
      usRows: renderPIIRows('us'),
      auRows: renderPIIRows('au'),
    }),
    [renderPIIRows]
  );

  const maxRows = useMemo(
    () => Math.max(...Object.values(piiCategories).map((arr) => arr.length)),
    []
  );

  const renderContent = () => {
    if (error) {
      return (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Unable to load PII settings. Please refresh the page or try again
            later.
          </AlertDescription>
        </Alert>
      );
    }

    if (isLoading || !piiData) {
      return <LoadingTable />;
    }

    return (
      <>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>General</TableHead>
              <TableHead>Australia</TableHead>
              <TableHead>USA & UK</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Array.from({ length: maxRows }, (_, i) => (
              <TableRow key={i}>
                <TableCell>{generalRows[i] || null}</TableCell>
                <TableCell>{auRows[i] || null}</TableCell>
                <TableCell>{usRows[i] || null}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="mt-4">
          <Button
            onClick={handleSave}
            disabled={!hasChanges || updatePiiMutation.isPending}
          >
            {updatePiiMutation.isPending ? 'Saving...' : 'Save Changes'}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div>
      <div>
        <h2 className="text-lg font-medium mb-1 flex items-center gap-2">
          Auto-Redact PII{' '}
          <Badge variant="secondary">
            <Sparkle className="w-3 mr-2" />
            Early Access
          </Badge>
        </h2>

        <p className="text-sm text-muted-foreground">
          Update your PII (personal identifiable information) redaction settings
          to choose what can be automatically redacted in your text and
          screenshots.
        </p>
      </div>

      <div>{renderContent()}</div>
    </div>
  );
}

export default PIITable;
