import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { useToast } from '@fluency/ui/components/ui/use-toast';

interface ReplaceScreenshotParams {
  file: File;
  stepId: string;
  fileType: string;
}
interface ReplaceScreenshotResponse {
  message: string;
  screenshotsToReplace: {
    screenshotDownloadUrl: string;
    screenshotId: string;
    screenshotKey: string;
  }[];
}

const useReplaceScreenshot = (
  documentId: string
): UseMutationResult<
  ReplaceScreenshotResponse,
  Error,
  ReplaceScreenshotParams
> => {
  const { accessToken } = useAuth();
  const apiUrl = import.meta.env.VITE_SERVER_API_URL;
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const replaceScreenshot = async (
    params: ReplaceScreenshotParams
  ): Promise<ReplaceScreenshotResponse> => {
    const { file, stepId, fileType } = params;
    const formData = new FormData();
    formData.append('fileBuffer', file);
    formData.append('stepId', stepId);
    formData.append('fileType', fileType);

    const response = await fetch(`${apiUrl}/screenshot/replace`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });

    if (!response.ok) {
      const error = await response.json();
      console.log(error);
      throw new Error('Failed to replace screenshot');
    }

    return response.json();
  };

  return useMutation<ReplaceScreenshotResponse, Error, ReplaceScreenshotParams>(
    {
      mutationFn: replaceScreenshot,
      onSuccess: (data) => {
        toast({
          title: 'Success',
          description: data.message,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ['docInfo', documentId],
        });
      },
      onError: (error) => {
        toast({
          title: 'Error',
          description: error.message,
        });
      },
    }
  );
};

export default useReplaceScreenshot;
