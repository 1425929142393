import React, { useState, useEffect, useCallback } from 'react';
import { CircleIcon } from '@radix-ui/react-icons';
import { ScreenshotType } from '@fluency/ui/features/ViewDocument/types/master';
import { useUpdateScreenshot } from '@fluency/ui/features/ViewDocument/hooks';
import debounce from 'lodash/debounce';

interface AnnotationOverlayProps {
  imageRef: React.RefObject<HTMLImageElement>;
  screenshot: ScreenshotType;
  documentationId: string;
}

type DragPoint = 'start' | 'end' | 'body' | 'none';

const DEFAULT_SHAPE_SIZE = 20;
const INITIAL_POSITION = 50;
const SAVE_DELAY = 1000;

export const AnnotationOverlay: React.FC<AnnotationOverlayProps> = ({
  imageRef,
  screenshot,
  documentationId,
}) => {
  const [dragPoint, setDragPoint] = useState<DragPoint>('none');
  const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(
    null
  );
  const [localCoords, setLocalCoords] = useState({
    x1: INITIAL_POSITION,
    y1: INITIAL_POSITION,
    x2: INITIAL_POSITION + DEFAULT_SHAPE_SIZE,
    y2: INITIAL_POSITION + DEFAULT_SHAPE_SIZE,
  });

  const { mutate: updateScreenshot } = useUpdateScreenshot({
    documentationId,
  });

  const annotation = screenshot.annotations[0];
  if (!annotation) return null;

  const debouncedSave = useCallback(
    debounce((coords) => {
      updateScreenshot({
        screenshotsToUpdate: [
          {
            screenshotId: screenshot.screenshotId,
            annotations: [
              {
                annotationId: annotation.screenshotAnnotationId,
                x1Coordinate: Math.round(coords.x1),
                y1Coordinate: Math.round(coords.y1),
                x2Coordinate: Math.round(coords.x2),
                y2Coordinate: Math.round(coords.y2),
              },
            ],
          },
        ],
      });
    }, SAVE_DELAY),
    [screenshot.screenshotId, annotation.screenshotAnnotationId]
  );

  useEffect(() => {
    if (annotation) {
      setLocalCoords({
        x1: annotation.x1Coordinate ?? INITIAL_POSITION,
        y1: annotation.y1Coordinate ?? INITIAL_POSITION,
        x2: annotation.x2Coordinate ?? INITIAL_POSITION + DEFAULT_SHAPE_SIZE,
        y2: annotation.y2Coordinate ?? INITIAL_POSITION + DEFAULT_SHAPE_SIZE,
      });
    }
  }, [annotation.shapeType]);

  useEffect(() => {
    debouncedSave(localCoords);
    return () => {
      debouncedSave.cancel();
    };
  }, [localCoords]);

  const getMousePosition = (e: React.MouseEvent) => {
    if (!imageRef.current) return null;
    const rect = imageRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    return {
      x: Math.max(0, Math.min(100, x)),
      y: Math.max(0, Math.min(100, y)),
    };
  };

  const handleMouseDown = (point: DragPoint) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setDragPoint(point);
    const pos = getMousePosition(e);
    if (pos) {
      setDragStart(pos);
    }
  };

  const handleMouseUp = () => {
    setDragPoint('none');
    setDragStart(null);
  };

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (annotation.shapeType === 'CIRCLE') {
      const pos = getMousePosition(e);
      if (pos) {
        setLocalCoords((prev) => ({ ...prev, x1: pos.x, y1: pos.y }));
      }
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (dragPoint === 'none' || !dragStart) return;

    const pos = getMousePosition(e);
    if (!pos) return;

    const dx = pos.x - dragStart.x;
    const dy = pos.y - dragStart.y;

    switch (dragPoint) {
      case 'start':
        setLocalCoords((prev) => ({ ...prev, x1: pos.x, y1: pos.y }));
        break;
      case 'end':
        setLocalCoords((prev) => ({ ...prev, x2: pos.x, y2: pos.y }));
        break;
      case 'body':
        setLocalCoords((prev) => ({
          x1: Math.max(0, Math.min(100, prev.x1 + dx)),
          y1: Math.max(0, Math.min(100, prev.y1 + dy)),
          x2: Math.max(0, Math.min(100, prev.x2 + dx)),
          y2: Math.max(0, Math.min(100, prev.y2 + dy)),
        }));
        setDragStart(pos);
        break;
    }
  };

  const renderShape = () => {
    const { x1, y1, x2, y2 } = localCoords;

    switch (annotation.shapeType) {
      case 'CIRCLE':
        return (
          <CircleIcon
            className="absolute w-7 h-7 cursor-move"
            style={{
              left: `${x1}%`,
              top: `${y1}%`,
              transform: 'translate(-50%, -50%)',
              color: annotation.color,
            }}
            onMouseDown={handleMouseDown('body')}
          />
        );

      case 'LINE':
      case 'ARROW': {
        const id = `arrowhead-${annotation.screenshotAnnotationId}`;
        return (
          <div
            className="absolute inset-0"
            onMouseDown={handleMouseDown('body')}
          >
            <svg width="100%" height="100%" className="absolute inset-0">
              {annotation.shapeType === 'ARROW' && (
                <defs>
                  <marker
                    id={id}
                    markerWidth="10"
                    markerHeight="7"
                    refX="9"
                    refY="3.5"
                    orient="auto"
                  >
                    <polygon
                      points="0 0, 10 3.5, 0 7"
                      fill={annotation.color}
                    />
                  </marker>
                </defs>
              )}
              <line
                x1={`${x1}%`}
                y1={`${y1}%`}
                x2={`${x2}%`}
                y2={`${y2}%`}
                stroke={annotation.color}
                strokeWidth="2"
                markerEnd={
                  annotation.shapeType === 'ARROW' ? `url(#${id})` : undefined
                }
                vectorEffect="non-scaling-stroke"
                style={{ cursor: 'move' }}
                className="hover:stroke-2"
              />
            </svg>
          </div>
        );
      }

      case 'RECTANGLE': {
        const width = Math.abs(x2 - x1);
        const height = Math.abs(y2 - y1);
        const left = Math.min(x1, x2);
        const top = Math.min(y1, y2);

        return (
          <div
            className="absolute border-2 cursor-move hover:bg-black/5"
            style={{
              left: `${left}%`,
              top: `${top}%`,
              width: `${width}%`,
              height: `${height}%`,
              borderColor: annotation.color,
            }}
            onMouseDown={handleMouseDown('body')}
          />
        );
      }

      default:
        return null;
    }
  };

  const renderHandles = () => {
    if (annotation.shapeType === 'CIRCLE') return null;

    const handleStyle =
      'w-3 h-3 rounded-full bg-white border-2 absolute cursor-move z-10';
    const { x1, y1, x2, y2 } = localCoords;

    return (
      <>
        <div
          className={handleStyle}
          style={{
            left: `${x1}%`,
            top: `${y1}%`,
            transform: 'translate(-50%, -50%)',
            borderColor: annotation.color,
          }}
          onMouseDown={handleMouseDown('start')}
        />
        <div
          className={handleStyle}
          style={{
            left: `${x2}%`,
            top: `${y2}%`,
            transform: 'translate(-50%, -50%)',
            borderColor: annotation.color,
          }}
          onMouseDown={handleMouseDown('end')}
        />
      </>
    );
  };

  if (!screenshot.annotation) return null;

  return (
    <div
      className="absolute inset-0"
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onClick={handleBackgroundClick}
    >
      {renderShape()}
      {renderHandles()}
    </div>
  );
};
