import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DocumentationResponse, ScreenshotType } from '../types/master.js';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';

interface AnnotationCoordinates {
  annotationX1: number;
  annotationY1: number;
}

interface CreateAnnotationRequest {
  screenshotId: string;
  shapeType?: 'RECTANGLE' | 'TEXT' | 'CIRCLE' | 'ARROW' | 'LINE';
  color?: string;
  firstCoordinates: AnnotationCoordinates;
}

interface UpdateScreenshotAnnotation {
  annotationId: string;
  x1Coordinate?: number;
  y1Coordinate?: number;
  x2Coordinate?: number;
  y2Coordinate?: number;
  color?: string;
  shapeType?: 'RECTANGLE' | 'TEXT' | 'CIRCLE' | 'ARROW' | 'LINE';
}

interface UpdateScreenshotRequest {
  screenshotId: string;
  hidden?: boolean;
  annotation?: boolean;
  annotations?: UpdateScreenshotAnnotation[];
}

interface UseScreenshotAnnotationProps {
  documentationId: string;
}

async function createAnnotation(
  request: CreateAnnotationRequest[],
  accessToken: string
): Promise<any> {
  const endpoint = '/screenshot/annotate';
  const apiUrl = import.meta.env.VITE_SERVER_API_URL;
  const response = await fetch(`${apiUrl}${endpoint}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ annotations: request }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to create annotation');
  }

  return response.json();
}

async function updateScreenshot(
  request: UpdateScreenshotRequest[],
  accessToken: string
): Promise<any> {
  const endpoint = '/screenshot/update';
  const apiUrl = import.meta.env.VITE_SERVER_API_URL;

  // Remove undefined properties from annotations
  const cleanRequest = request.map((screenshot) => {
    const clean: UpdateScreenshotRequest = {
      screenshotId: screenshot.screenshotId,
    };

    if (screenshot.hidden !== undefined) clean.hidden = screenshot.hidden;
    if (screenshot.annotation !== undefined)
      clean.annotation = screenshot.annotation;

    if (screenshot.annotations) {
      clean.annotations = screenshot.annotations.map((annotation) => {
        const cleanAnnotation: UpdateScreenshotAnnotation = {
          annotationId: annotation.annotationId,
        };

        if (annotation.x1Coordinate !== undefined)
          cleanAnnotation.x1Coordinate = annotation.x1Coordinate;
        if (annotation.y1Coordinate !== undefined)
          cleanAnnotation.y1Coordinate = annotation.y1Coordinate;
        if (annotation.x2Coordinate !== undefined)
          cleanAnnotation.x2Coordinate = annotation.x2Coordinate;
        if (annotation.y2Coordinate !== undefined)
          cleanAnnotation.y2Coordinate = annotation.y2Coordinate;
        if (annotation.color !== undefined)
          cleanAnnotation.color = annotation.color;
        if (annotation.shapeType !== undefined)
          cleanAnnotation.shapeType = annotation.shapeType;

        return cleanAnnotation;
      });
    }

    return clean;
  });

  const response = await fetch(`${apiUrl}${endpoint}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ screenshotsToUpdate: cleanRequest }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to update screenshot');
  }

  return response.json();
}

export const useScreenshotAnnotation = ({
  documentationId,
}: UseScreenshotAnnotationProps) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  const createAnnotationMutation = useMutation({
    mutationFn: (request: CreateAnnotationRequest[]) => {
      if (!accessToken) throw new Error('Access token is missing');
      return createAnnotation(request, accessToken);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['docInfo', documentationId] });
    },
    onError: (error) => {
      console.error('Error creating annotation:', error);
      toast({
        title: 'Error',
        description: 'Failed to create annotation. Please try again.',
      });
    },
  });

  const updateScreenshotMutation = useMutation<
    void,
    Error,
    { screenshotsToUpdate: UpdateScreenshotRequest[] },
    { previousDocInfo: DocumentationResponse | undefined }
  >({
    mutationFn: ({ screenshotsToUpdate }) => {
      if (!accessToken) throw new Error('Access token is missing');
      return updateScreenshot(screenshotsToUpdate, accessToken);
    },
    onMutate: async ({ screenshotsToUpdate }) => {
      await queryClient.cancelQueries({
        queryKey: ['docInfo', documentationId],
      });
      const previousDocInfo = queryClient.getQueryData<DocumentationResponse>([
        'docInfo',
        documentationId,
      ]);

      if (previousDocInfo) {
        queryClient.setQueryData<DocumentationResponse>(
          ['docInfo', documentationId],
          (old) => {
            if (!old) return old;
            const updatedSteps = old.documentation.steps.map((step) => ({
              ...step,
              screenshots: step.screenshots.map(
                (screenshot: ScreenshotType) => {
                  const update = screenshotsToUpdate.find(
                    (s) => s.screenshotId === screenshot.screenshotId
                  );
                  if (update) {
                    const updatedScreenshot = { ...screenshot };

                    if (update.hidden !== undefined)
                      updatedScreenshot.hidden = update.hidden;
                    if (update.annotation !== undefined)
                      updatedScreenshot.annotation = update.annotation;

                    if (update.annotations) {
                      updatedScreenshot.annotations =
                        screenshot.annotations.map((existing) => {
                          const updateAnnotation = update.annotations?.find(
                            (a) =>
                              a.annotationId === existing.screenshotAnnotationId
                          );

                          if (updateAnnotation) {
                            return {
                              ...existing,
                              ...(updateAnnotation.shapeType && {
                                shapeType: updateAnnotation.shapeType,
                              }),
                              ...(updateAnnotation.x1Coordinate !==
                                undefined && {
                                x1Coordinate: updateAnnotation.x1Coordinate,
                              }),
                              ...(updateAnnotation.y1Coordinate !==
                                undefined && {
                                y1Coordinate: updateAnnotation.y1Coordinate,
                              }),
                              ...(updateAnnotation.x2Coordinate !==
                                undefined && {
                                x2Coordinate: updateAnnotation.x2Coordinate,
                              }),
                              ...(updateAnnotation.y2Coordinate !==
                                undefined && {
                                y2Coordinate: updateAnnotation.y2Coordinate,
                              }),
                              ...(updateAnnotation.color && {
                                color: updateAnnotation.color,
                              }),
                            };
                          }
                          return existing;
                        });
                    }
                    return updatedScreenshot;
                  }
                  return screenshot;
                }
              ),
            }));
            return {
              ...old,
              documentation: { ...old.documentation, steps: updatedSteps },
            };
          }
        );
      }
      return { previousDocInfo };
    },
    onError: (err, _newScreenshots, context) => {
      if (context?.previousDocInfo) {
        queryClient.setQueryData(
          ['docInfo', documentationId],
          context.previousDocInfo
        );
      }
      console.error('Error updating screenshot:', err);
      toast({
        title: 'Error',
        description: 'Failed to update screenshot. Please try again.',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['docInfo', documentationId] });
    },
  });

  return {
    createAnnotation: createAnnotationMutation.mutate,
    updateScreenshot: updateScreenshotMutation.mutate,
    isLoading:
      createAnnotationMutation.isPending || updateScreenshotMutation.isPending,
    isError:
      createAnnotationMutation.isError || updateScreenshotMutation.isError,
  };
};

export default useScreenshotAnnotation;
