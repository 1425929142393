import React, { Fragment } from 'react';
import { EnhancedStep, ScreenshotType, Step } from '../types/master';
import {
  EditStepActions,
  EditStepDescriptionInput,
  ScreenshotItem,
} from './index';
import { stepTypeColors, getStepTypeIcon } from '../util/iconHelper';

interface StepContentProps {
  step: EnhancedStep;
  isLatestVersion: boolean;
  docId: string;
  editingId: string | null;
  editStepDescription: string;
  handleSaveStepDescription: (editingId: string | null) => void;
  handleEditStepDescription: ({
    stepId,
    description,
  }: {
    stepId: string | null;
    description: string;
  }) => void;
  handleUpdateStepDescription: ({
    stepId,
    description,
  }: {
    stepId: string;
    description: string;
  }) => void;
  handleDeleteStep: (stepId: string) => void;
  filteredSteps: Step[];
  isEditMode: boolean;
}

export const StepContent = React.forwardRef<
  HTMLTableRowElement,
  StepContentProps
>(
  (
    {
      step,
      isLatestVersion,
      docId,
      editingId,
      editStepDescription,
      handleSaveStepDescription,
      handleEditStepDescription,
      handleUpdateStepDescription,
      handleDeleteStep,
      filteredSteps,
      isEditMode,
    },
    ref
  ) => (
    <Fragment>
      <tr ref={ref} className="print:break-inside-avoid">
        <td className="max-w-0 px-0 py-3 align-top">
          <div
            className={`${
              stepTypeColors[step.stepType as keyof typeof stepTypeColors] || ''
            } ${
              !step.isStepTypeStep && 'text-slate-950 p-3'
            } rounded-xl flex justify-between mt-6`}
          >
            <div className="w-full">
              <div className="flex row justify-between h-5">
                <span className="font-semibold flex items-center">
                  {getStepTypeIcon(step.stepType)}
                  {step.isStepTypeStep && step.stepLabel}
                  <span className="dark:text-black">
                    {!step.isStepTypeStep &&
                      step.stepType.charAt(0).toUpperCase() +
                        step.stepType.slice(1).toLowerCase()}
                  </span>
                </span>
                {isLatestVersion && isEditMode && (
                  <EditStepActions
                    editingId={editingId}
                    handleSaveStepDescription={handleSaveStepDescription}
                    handleEditStepDescription={handleEditStepDescription}
                    handleDeleteStep={handleDeleteStep}
                    isStepTypeStep={step.isStepTypeStep}
                    isEditButtonPressed={editingId === step.stepId}
                    stepId={step.stepId}
                    stepDescription={step.stepDescription}
                    screenshotName={step.screenshotName ?? ''}
                    stepNumber={step.stepNumber}
                    screenshotId={step.screenshotId}
                    documentId={docId}
                  />
                )}
              </div>
              <div className="mt-3 text-wrap print:mt-0">
                <EditStepDescriptionInput
                  isStepTypeStep={false}
                  handleEditStepDescription={handleEditStepDescription}
                  handleUpdateStepDescription={handleUpdateStepDescription}
                  editStepDescription={editStepDescription}
                  editingId={editingId}
                  filteredSteps={filteredSteps}
                  stepId={step.stepId}
                  stepDescription={step.stepDescription}
                  canEdit={isLatestVersion}
                  isEditMode={isEditMode}
                />
              </div>
            </div>
          </div>
        </td>
      </tr>
      {step.screenshotUrl && step.screenshotName && (
        <tr
          key={step.stepId}
          className="border-b border-gray-100 transition-opacity duration-300"
        >
          <td className="">
            <ScreenshotItem
              docId={docId}
              stepId={step.stepId}
              isScreenshotLoading={false}
              isEditMode={isEditMode}
              screenshot={step.screenshots[0] ?? ({} as ScreenshotType)}
            />
          </td>
        </tr>
      )}
    </Fragment>
  )
);
