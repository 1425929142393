import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';
import { RefreshCw } from 'lucide-react';
import { DataTablePagination } from './components/DataTablePagination';
import { DataTableToolbar } from './components/DataTableToolbar';
import type { Team } from './types/types';
import useGetTeams from '@fluency/ui/hooks/useGetTeams';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@fluency/ui/components/ui/table';
import { Card, CardContent, CardHeader } from '@fluency/ui/components/ui/card';
import TeamManagementDialog from './components/TeamManagementDialog';

interface TeamSettingsProps {
  columns: ColumnDef<Team, any>[];
}

export const TeamSettings = ({ columns }: TeamSettingsProps) => {
  const { data, isLoading, isError } = useGetTeams();
  const [selectedTeamId, setSelectedTeamId] = React.useState<string | null>(
    null
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');

  const table = useReactTable({
    data: data ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
      columnFilters,
      globalFilter,
    },
    enableRowSelection: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const handleManageTeam = (teamId: string) => {
    setSelectedTeamId(teamId);
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium mb-1">Teams</h2>
        <p className="text-sm text-muted-foreground">
          Group users into teams to manage permissions. Control team access to
          vaults, documents and process maps across your organisation.
        </p>
      </div>

      <Card className="border bg-background overflow-hidden">
        <CardHeader className="border-b">
          <DataTableToolbar table={table} />
        </CardHeader>
        <CardContent className="p-0">
          <Table className="w-full table-fixed">
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    <div className="flex items-center justify-center text-muted-foreground">
                      <RefreshCw className="w-4 h-4 mr-2 animate-spin" />
                      <span className="text-sm">Loading teams...</span>
                    </div>
                  </TableCell>
                </TableRow>
              ) : isError ? (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center text-muted-foreground"
                  >
                    Error loading teams.
                  </TableCell>
                </TableRow>
              ) : table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    className="cursor-pointer hover:bg-accent/50"
                    onClick={() => handleManageTeam(row.original.teamId)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center text-muted-foreground"
                  >
                    No teams found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className="border-t p-4">
            <DataTablePagination table={table} />
          </div>
        </CardContent>
      </Card>

      {selectedTeamId && (
        <TeamManagementDialog
          isOpen={!!selectedTeamId}
          onOpenChange={(open) => {
            if (!open) setSelectedTeamId(null);
          }}
          teamId={selectedTeamId}
        />
      )}
    </div>
  );
};

export default TeamSettings;
