import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Workflow } from './types';
import { createHeaders, showErrorToast, showSuccessToast } from './utils';
import { useNavigate } from '@tanstack/react-router';

interface CreateWorkflowPayload {
  vaultId: string;
  name: string;
  description: string;
  nodes: any[]; // Update with proper node type if available
  edges: any[]; // Update with proper edge type if available
}

type CreateWorkflowContext = {
  previousWorkflows?: Workflow[];
};

// Update the mutation hook
export const useCreateWorkflow = () => {
  const { accessToken } = useAuth();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<
    Workflow,
    Error,
    CreateWorkflowPayload,
    CreateWorkflowContext
  >({
    mutationFn: async (payload: CreateWorkflowPayload): Promise<Workflow> => {
      const requestOptions: RequestInit = {
        method: 'POST',
        headers: createHeaders(accessToken),
        body: JSON.stringify(payload),
      };

      try {
        const response = await fetch(`${apiBaseUrl}/workflows`, requestOptions);

        if (!response.ok) {
          const errorBody = await response.text();
          console.error('Error response body:', errorBody);
          throw new Error(
            `HTTP error! status: ${response.status}, body: ${errorBody}`
          );
        }

        const result = await response.json();
        console.log('Successful response:', result);
        return result.workflow;
      } catch (error) {
        console.error('Error in createWorkflow:', error);
        throw error;
      }
    },
    onError: (error) => {
      console.error('Error in onError callback:', error);
      showErrorToast('Failed to create workflow. Please try again.');
    },
    onSuccess: (data) => {
      console.log('Workflow created successfully:', data);
      showSuccessToast('Workflow created successfully.');
      navigate({
        to: '/workflow/$id',
        params: { id: data.id },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['workflows'] });
    },
  });
};
