import React from 'react';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@fluency/ui/components/ui/collapsible';
import { Button } from '@fluency/ui/components/ui/button';
import { ChevronDown } from 'lucide-react';
import { CropSettings } from './features/CropSettings';
import { RedactionSettings } from './features/RedactionSettings';
import { AnnotationSettings } from './features/annotationComponents/AnnotationSettings';
import { ChangeScreenshotSettings } from './features/ChangeScreenshotSettings';
import { ScreenshotType } from '../../../types/master';
import { RedactionRectangle, RedactionColor } from '../types';
import { NormalizedCropData } from '../types';

interface SidebarProps {
  imageKey: string;
  stepId: string;
  documentationId: string;
  screenshot: ScreenshotType;
  activeFeature: string | null;
  onFeatureChange: (feature: string | null) => void;
  cropData: NormalizedCropData | null;
  aspectRatio: number | null;
  onAspectRatioChange: (ratio: number | null) => void;
  onSaveCrop: () => void;
  redactionRectangles: RedactionRectangle[];
  onRedactionRectanglesChange: (rectangles: RedactionRectangle[]) => void;
  selectedRectangles: number[];
  currentRedactionColor: RedactionColor;
  onRedactionColorChange: (color: RedactionColor) => void;
  onSaveRedactions: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({
  stepId,
  documentationId,
  screenshot,
  activeFeature,
  onFeatureChange,
  cropData,
  aspectRatio,
  onAspectRatioChange,
  onSaveCrop,
  redactionRectangles,
  onRedactionRectanglesChange,
  selectedRectangles,
  currentRedactionColor,
  onRedactionColorChange,
}) => {
  const features = [
    {
      value: 'crop',
      title: 'Crop Image',
      content: (
        <CropSettings
          cropData={cropData}
          aspectRatio={aspectRatio}
          onAspectRatioChange={onAspectRatioChange}
          onSave={onSaveCrop}
        />
      ),
    },
    {
      value: 'redact',
      title: 'Redact Information',
      content: (
        <RedactionSettings
          screenshot={screenshot}
          documentationId={documentationId}
          rectangles={redactionRectangles}
          onRectanglesChange={onRedactionRectanglesChange}
          selectedRectangles={selectedRectangles}
          currentColor={currentRedactionColor}
          onColorChange={onRedactionColorChange}
          onClose={() => onFeatureChange(null)}
        />
      ),
    },
    {
      value: 'annotate',
      title: 'Add Annotations',
      content: <AnnotationSettings screenshot={screenshot} />,
    },
    {
      value: 'change',
      title: 'Change Screenshot',
      content: (
        <ChangeScreenshotSettings
          stepId={stepId}
          documentId={documentationId}
          onClose={() => onFeatureChange(null)}
        />
      ),
    },
  ];

  const handleFeatureClick = (value: string) => {
    onFeatureChange(activeFeature === value ? null : value);
  };

  return (
    <div className="w-72 min-w-72 h-full">
      <div className="h-full overflow-y-auto">
        <div className="p-4 space-y-2">
          {features.map((feature) => (
            <Collapsible
              key={feature.value}
              open={activeFeature === feature.value}
              onOpenChange={() => handleFeatureClick(feature.value)}
              className={`
                rounded-lg
                transition-all
                duration-200
                ease-in-out
                ${activeFeature === feature.value ? 'bg-muted/50' : ''}
              `}
            >
              <CollapsibleTrigger asChild>
                <Button
                  variant="ghost"
                  className={`
                    w-full 
                    justify-between 
                    py-3 
                    px-4 
                    rounded-lg 
                    hover:bg-muted/50
                    transition-colors
                    ${activeFeature === feature.value ? 'bg-muted/50' : ''}
                  `}
                >
                  {feature.title}
                  <ChevronDown
                    className={`
                      w-4 
                      h-4 
                      shrink-0 
                      transition-transform 
                      duration-200
                      ${activeFeature === feature.value ? 'rotate-180' : ''}
                    `}
                  />
                </Button>
              </CollapsibleTrigger>
              <CollapsibleContent className="overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down">
                <div className="px-4 py-3">
                  <div className="space-y-4">{feature.content}</div>
                </div>
              </CollapsibleContent>
            </Collapsible>
          ))}
        </div>
      </div>
    </div>
  );
};
