import React, { forwardRef } from 'react';
import { NaturalSize } from '../types';
import { getScaledStyle } from '../utils/cropUtils';
import { MAX_SCALE } from '../constants';
import { transitionStyles } from '../styles';

interface CropCanvasProps {
  onClick: (e: React.MouseEvent<HTMLCanvasElement>) => void;
  naturalSize: NaturalSize | null;
  children?: React.ReactNode;
}

export const CropCanvas = forwardRef<HTMLCanvasElement, CropCanvasProps>(
  ({ onClick, naturalSize, children }, ref) => (
    <div className="relative">
      <canvas
        ref={ref}
        className="h-auto cursor-pointer transition-all duration-300 ease-in-out"
        onClick={onClick}
        style={{
          ...(naturalSize
            ? getScaledStyle(naturalSize.width, MAX_SCALE)
            : { maxWidth: '100%' }),
          ...transitionStyles.fadeIn,
        }}
      />
      {children}
    </div>
  )
);
