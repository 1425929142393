/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as DocumentsImport } from './routes/documents'
import { Route as ShareDocumentImport } from './routes/share.$document'
import { Route as ProfileSettingImport } from './routes/profile.$setting'

// Create Virtual Routes

const UnauthorizedLazyImport = createFileRoute('/unauthorized')()
const SubscriptionLazyImport = createFileRoute('/subscription')()
const OrgformLazyImport = createFileRoute('/orgform')()
const NotificationsLazyImport = createFileRoute('/notifications')()
const LogoutLazyImport = createFileRoute('/logout')()
const LoginLazyImport = createFileRoute('/login')()
const HomeLazyImport = createFileRoute('/home')()
const CallbackLazyImport = createFileRoute('/callback')()
const R404LazyImport = createFileRoute('/404')()
const IndexLazyImport = createFileRoute('/')()
const WorkflowIdLazyImport = createFileRoute('/workflow/$id')()
const VaultIdLazyImport = createFileRoute('/vault/$id')()
const DocumentIdLazyImport = createFileRoute('/document/$id')()

// Create/Update Routes

const UnauthorizedLazyRoute = UnauthorizedLazyImport.update({
  id: '/unauthorized',
  path: '/unauthorized',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/unauthorized.lazy').then((d) => d.Route))

const SubscriptionLazyRoute = SubscriptionLazyImport.update({
  id: '/subscription',
  path: '/subscription',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/subscription.lazy').then((d) => d.Route))

const OrgformLazyRoute = OrgformLazyImport.update({
  id: '/orgform',
  path: '/orgform',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/orgform.lazy').then((d) => d.Route))

const NotificationsLazyRoute = NotificationsLazyImport.update({
  id: '/notifications',
  path: '/notifications',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/notifications.lazy').then((d) => d.Route))

const LogoutLazyRoute = LogoutLazyImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/logout.lazy').then((d) => d.Route))

const LoginLazyRoute = LoginLazyImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/login.lazy').then((d) => d.Route))

const HomeLazyRoute = HomeLazyImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/home.lazy').then((d) => d.Route))

const CallbackLazyRoute = CallbackLazyImport.update({
  id: '/callback',
  path: '/callback',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/callback.lazy').then((d) => d.Route))

const R404LazyRoute = R404LazyImport.update({
  id: '/404',
  path: '/404',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/404.lazy').then((d) => d.Route))

const DocumentsRoute = DocumentsImport.update({
  id: '/documents',
  path: '/documents',
  getParentRoute: () => rootRoute,
} as any)

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const WorkflowIdLazyRoute = WorkflowIdLazyImport.update({
  id: '/workflow/$id',
  path: '/workflow/$id',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/workflow.$id.lazy').then((d) => d.Route))

const VaultIdLazyRoute = VaultIdLazyImport.update({
  id: '/vault/$id',
  path: '/vault/$id',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/vault.$id.lazy').then((d) => d.Route))

const DocumentIdLazyRoute = DocumentIdLazyImport.update({
  id: '/document/$id',
  path: '/document/$id',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/document.$id.lazy').then((d) => d.Route))

const ShareDocumentRoute = ShareDocumentImport.update({
  id: '/share/$document',
  path: '/share/$document',
  getParentRoute: () => rootRoute,
} as any)

const ProfileSettingRoute = ProfileSettingImport.update({
  id: '/profile/$setting',
  path: '/profile/$setting',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/documents': {
      id: '/documents'
      path: '/documents'
      fullPath: '/documents'
      preLoaderRoute: typeof DocumentsImport
      parentRoute: typeof rootRoute
    }
    '/404': {
      id: '/404'
      path: '/404'
      fullPath: '/404'
      preLoaderRoute: typeof R404LazyImport
      parentRoute: typeof rootRoute
    }
    '/callback': {
      id: '/callback'
      path: '/callback'
      fullPath: '/callback'
      preLoaderRoute: typeof CallbackLazyImport
      parentRoute: typeof rootRoute
    }
    '/home': {
      id: '/home'
      path: '/home'
      fullPath: '/home'
      preLoaderRoute: typeof HomeLazyImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginLazyImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutLazyImport
      parentRoute: typeof rootRoute
    }
    '/notifications': {
      id: '/notifications'
      path: '/notifications'
      fullPath: '/notifications'
      preLoaderRoute: typeof NotificationsLazyImport
      parentRoute: typeof rootRoute
    }
    '/orgform': {
      id: '/orgform'
      path: '/orgform'
      fullPath: '/orgform'
      preLoaderRoute: typeof OrgformLazyImport
      parentRoute: typeof rootRoute
    }
    '/subscription': {
      id: '/subscription'
      path: '/subscription'
      fullPath: '/subscription'
      preLoaderRoute: typeof SubscriptionLazyImport
      parentRoute: typeof rootRoute
    }
    '/unauthorized': {
      id: '/unauthorized'
      path: '/unauthorized'
      fullPath: '/unauthorized'
      preLoaderRoute: typeof UnauthorizedLazyImport
      parentRoute: typeof rootRoute
    }
    '/profile/$setting': {
      id: '/profile/$setting'
      path: '/profile/$setting'
      fullPath: '/profile/$setting'
      preLoaderRoute: typeof ProfileSettingImport
      parentRoute: typeof rootRoute
    }
    '/share/$document': {
      id: '/share/$document'
      path: '/share/$document'
      fullPath: '/share/$document'
      preLoaderRoute: typeof ShareDocumentImport
      parentRoute: typeof rootRoute
    }
    '/document/$id': {
      id: '/document/$id'
      path: '/document/$id'
      fullPath: '/document/$id'
      preLoaderRoute: typeof DocumentIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/vault/$id': {
      id: '/vault/$id'
      path: '/vault/$id'
      fullPath: '/vault/$id'
      preLoaderRoute: typeof VaultIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/workflow/$id': {
      id: '/workflow/$id'
      path: '/workflow/$id'
      fullPath: '/workflow/$id'
      preLoaderRoute: typeof WorkflowIdLazyImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/documents': typeof DocumentsRoute
  '/404': typeof R404LazyRoute
  '/callback': typeof CallbackLazyRoute
  '/home': typeof HomeLazyRoute
  '/login': typeof LoginLazyRoute
  '/logout': typeof LogoutLazyRoute
  '/notifications': typeof NotificationsLazyRoute
  '/orgform': typeof OrgformLazyRoute
  '/subscription': typeof SubscriptionLazyRoute
  '/unauthorized': typeof UnauthorizedLazyRoute
  '/profile/$setting': typeof ProfileSettingRoute
  '/share/$document': typeof ShareDocumentRoute
  '/document/$id': typeof DocumentIdLazyRoute
  '/vault/$id': typeof VaultIdLazyRoute
  '/workflow/$id': typeof WorkflowIdLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/documents': typeof DocumentsRoute
  '/404': typeof R404LazyRoute
  '/callback': typeof CallbackLazyRoute
  '/home': typeof HomeLazyRoute
  '/login': typeof LoginLazyRoute
  '/logout': typeof LogoutLazyRoute
  '/notifications': typeof NotificationsLazyRoute
  '/orgform': typeof OrgformLazyRoute
  '/subscription': typeof SubscriptionLazyRoute
  '/unauthorized': typeof UnauthorizedLazyRoute
  '/profile/$setting': typeof ProfileSettingRoute
  '/share/$document': typeof ShareDocumentRoute
  '/document/$id': typeof DocumentIdLazyRoute
  '/vault/$id': typeof VaultIdLazyRoute
  '/workflow/$id': typeof WorkflowIdLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/documents': typeof DocumentsRoute
  '/404': typeof R404LazyRoute
  '/callback': typeof CallbackLazyRoute
  '/home': typeof HomeLazyRoute
  '/login': typeof LoginLazyRoute
  '/logout': typeof LogoutLazyRoute
  '/notifications': typeof NotificationsLazyRoute
  '/orgform': typeof OrgformLazyRoute
  '/subscription': typeof SubscriptionLazyRoute
  '/unauthorized': typeof UnauthorizedLazyRoute
  '/profile/$setting': typeof ProfileSettingRoute
  '/share/$document': typeof ShareDocumentRoute
  '/document/$id': typeof DocumentIdLazyRoute
  '/vault/$id': typeof VaultIdLazyRoute
  '/workflow/$id': typeof WorkflowIdLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/documents'
    | '/404'
    | '/callback'
    | '/home'
    | '/login'
    | '/logout'
    | '/notifications'
    | '/orgform'
    | '/subscription'
    | '/unauthorized'
    | '/profile/$setting'
    | '/share/$document'
    | '/document/$id'
    | '/vault/$id'
    | '/workflow/$id'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/documents'
    | '/404'
    | '/callback'
    | '/home'
    | '/login'
    | '/logout'
    | '/notifications'
    | '/orgform'
    | '/subscription'
    | '/unauthorized'
    | '/profile/$setting'
    | '/share/$document'
    | '/document/$id'
    | '/vault/$id'
    | '/workflow/$id'
  id:
    | '__root__'
    | '/'
    | '/documents'
    | '/404'
    | '/callback'
    | '/home'
    | '/login'
    | '/logout'
    | '/notifications'
    | '/orgform'
    | '/subscription'
    | '/unauthorized'
    | '/profile/$setting'
    | '/share/$document'
    | '/document/$id'
    | '/vault/$id'
    | '/workflow/$id'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  DocumentsRoute: typeof DocumentsRoute
  R404LazyRoute: typeof R404LazyRoute
  CallbackLazyRoute: typeof CallbackLazyRoute
  HomeLazyRoute: typeof HomeLazyRoute
  LoginLazyRoute: typeof LoginLazyRoute
  LogoutLazyRoute: typeof LogoutLazyRoute
  NotificationsLazyRoute: typeof NotificationsLazyRoute
  OrgformLazyRoute: typeof OrgformLazyRoute
  SubscriptionLazyRoute: typeof SubscriptionLazyRoute
  UnauthorizedLazyRoute: typeof UnauthorizedLazyRoute
  ProfileSettingRoute: typeof ProfileSettingRoute
  ShareDocumentRoute: typeof ShareDocumentRoute
  DocumentIdLazyRoute: typeof DocumentIdLazyRoute
  VaultIdLazyRoute: typeof VaultIdLazyRoute
  WorkflowIdLazyRoute: typeof WorkflowIdLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  DocumentsRoute: DocumentsRoute,
  R404LazyRoute: R404LazyRoute,
  CallbackLazyRoute: CallbackLazyRoute,
  HomeLazyRoute: HomeLazyRoute,
  LoginLazyRoute: LoginLazyRoute,
  LogoutLazyRoute: LogoutLazyRoute,
  NotificationsLazyRoute: NotificationsLazyRoute,
  OrgformLazyRoute: OrgformLazyRoute,
  SubscriptionLazyRoute: SubscriptionLazyRoute,
  UnauthorizedLazyRoute: UnauthorizedLazyRoute,
  ProfileSettingRoute: ProfileSettingRoute,
  ShareDocumentRoute: ShareDocumentRoute,
  DocumentIdLazyRoute: DocumentIdLazyRoute,
  VaultIdLazyRoute: VaultIdLazyRoute,
  WorkflowIdLazyRoute: WorkflowIdLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/documents",
        "/404",
        "/callback",
        "/home",
        "/login",
        "/logout",
        "/notifications",
        "/orgform",
        "/subscription",
        "/unauthorized",
        "/profile/$setting",
        "/share/$document",
        "/document/$id",
        "/vault/$id",
        "/workflow/$id"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/documents": {
      "filePath": "documents.tsx"
    },
    "/404": {
      "filePath": "404.lazy.tsx"
    },
    "/callback": {
      "filePath": "callback.lazy.tsx"
    },
    "/home": {
      "filePath": "home.lazy.tsx"
    },
    "/login": {
      "filePath": "login.lazy.tsx"
    },
    "/logout": {
      "filePath": "logout.lazy.tsx"
    },
    "/notifications": {
      "filePath": "notifications.lazy.tsx"
    },
    "/orgform": {
      "filePath": "orgform.lazy.tsx"
    },
    "/subscription": {
      "filePath": "subscription.lazy.tsx"
    },
    "/unauthorized": {
      "filePath": "unauthorized.lazy.tsx"
    },
    "/profile/$setting": {
      "filePath": "profile.$setting.tsx"
    },
    "/share/$document": {
      "filePath": "share.$document.tsx"
    },
    "/document/$id": {
      "filePath": "document.$id.lazy.tsx"
    },
    "/vault/$id": {
      "filePath": "vault.$id.lazy.tsx"
    },
    "/workflow/$id": {
      "filePath": "workflow.$id.lazy.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
