import { ColumnDef } from '@tanstack/react-table';
import { Team } from '../types/types';
import { Button } from '@fluency/ui/components/ui/button';
import { ArrowUpRight } from 'lucide-react';

export const getColumns = (): ColumnDef<Team>[] => [
  {
    accessorKey: 'teamName',
    header: 'Team Name',
    cell: ({ row }) => (
      <div className="max-w-[280px] truncate">{row.getValue('teamName')}</div>
    ),
  },
  {
    accessorKey: 'teamDescription',
    header: 'Description',
    cell: ({ row }) => (
      <div className="flex items-center justify-between min-w-0">
        <span className="truncate flex-1 mr-4">
          {row.getValue('teamDescription') || '-'}
        </span>
        <Button
          variant="ghost"
          size="sm"
          className="flex-shrink-0 flex items-center hover:bg-transparent"
        >
          View
          <ArrowUpRight className="w-4 h-4 ml-1" />
        </Button>
      </div>
    ),
  },
];
