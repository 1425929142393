import React, { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import { Separator } from '@fluency/ui/components/ui/separator';
import { BoxSelect, Save } from 'lucide-react';
import { AutoRedactButton } from './AutoRedactButton';
import {
  RedactionRectangle,
  RedactionColor,
  REDACTION_COLORS,
} from '../../types';
import useUpdateImagePII from '../../../../hooks/useImagePII';
import { ScreenshotType } from '@fluency/ui/features/ViewDocument/types/master';

interface ColorButtonProps {
  color: RedactionColor;
  onClick: (color: RedactionColor) => void;
  isSelected: boolean;
}

const ColorButton: React.FC<ColorButtonProps> = ({
  color,
  onClick,
  isSelected,
}) => (
  <button
    className={`w-8 h-8 rounded-lg m-1 text-gray-700 font-medium ${
      isSelected ? 'ring-2 ring-offset-2 ring-blue-500' : ''
    } ${color === 'blur' ? 'w-full border border-gray-400' : ''}`}
    style={color === 'blur' ? {} : { backgroundColor: color }}
    onClick={() => onClick(color)}
  >
    {color === 'blur' && (
      <div className="flex items-center justify-center gap-2">
        <BoxSelect className="w-5" />
        <span>Blur</span>
      </div>
    )}
  </button>
);

interface RedactionSettingsProps {
  screenshot: ScreenshotType;
  documentationId: string;
  rectangles: RedactionRectangle[];
  onRectanglesChange: (rectangles: RedactionRectangle[]) => void;
  selectedRectangles: number[];
  currentColor: RedactionColor;
  onColorChange: (color: RedactionColor) => void;
  onClose?: () => void;
}

export const RedactionSettings: React.FC<RedactionSettingsProps> = ({
  screenshot,
  documentationId,
  rectangles,
  onRectanglesChange,
  selectedRectangles,
  currentColor,
  onColorChange,
  onClose,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const updateImagePIIMutation = useUpdateImagePII(documentationId);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const transformedRectangles = rectangles.map((rect) => ({
        xCoordinate: Math.round(rect.x),
        yCoordinate: Math.round(rect.y),
        width: rect.width,
        height: rect.height,
        shape: 'rectangle' as const,
        type: rect.type,
        colour: rect.colour,
      }));

      const data = {
        screenshotId: screenshot.screenshotId,
        redactionProperties: transformedRectangles,
        documentationId,
        imageKey: screenshot.screenshotKey,
      };

      await updateImagePIIMutation.mutateAsync(data);

      // Reset state and close dialog if provided
      onRectanglesChange([]);
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error('Error saving redactions:', error);
      // You might want to show an error toast here
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <label className="text-sm font-medium">Redaction Type</label>
        <p className="text-xs text-gray-600">
          Click and drag to draw redaction area
        </p>
        <div className="flex flex-wrap">
          {REDACTION_COLORS.map((color) => (
            <ColorButton
              key={color}
              color={color}
              onClick={onColorChange}
              isSelected={color === currentColor}
            />
          ))}
        </div>
        <p className="text-xs text-gray-600">
          {selectedRectangles.length === 0
            ? 'Color will be applied to new redactions'
            : `Color will be applied to ${selectedRectangles.length} selected redaction(s)`}
        </p>
      </div>

      <Separator />

      <div className="space-y-2">
        <AutoRedactButton imageKey={screenshot.screenshotKey} />

        <Separator className="my-2" />

        <Button
          onClick={handleSave}
          className="w-full"
          size="sm"
          disabled={
            rectangles.length === 0 ||
            isSaving ||
            updateImagePIIMutation.isPending
          }
        >
          <Save className="w-4 h-4 mr-2" />
          {isSaving || updateImagePIIMutation.isPending
            ? 'Saving...'
            : 'Save Redactions'}
        </Button>
      </div>

      {rectangles.length > 0 && (
        <p className="text-xs text-gray-500 mt-2">
          {rectangles.length} redaction{rectangles.length === 1 ? '' : 's'}{' '}
          added
        </p>
      )}

      {updateImagePIIMutation.isError && (
        <p className="text-xs text-red-500 mt-2">
          Error saving redactions. Please try again.
        </p>
      )}
    </div>
  );
};
