export enum NotificationItemType {
  RESOURCE_REQUEST_OVERDUE = 'RESOURCE_REQUEST_OVERDUE',
  RESOURCE_REQUEST_ASSIGNED = 'RESOURCE_REQUEST_ASSIGNED',
  RESOURCE_REQUEST_CANCELLED = 'RESOURCE_REQUEST_CANCELLED',
  RESOURCE_REQUEST_UPDATED = 'RESOURCE_REQUEST_UPDATED',
  RESOURCE_REQUEST_COMPLETED = 'RESOURCE_REQUEST_COMPLETED',
}

export interface Resource {
  id: string;
  name: string;
  type: 'document' | 'workflow';
}

export interface Vault {
  id: string;
  name: string;
}

export interface User {
  id: string;
  name: string;
}

export interface ApiNotification {
  id: string;
  userId: string;
  type: NotificationItemType;
  message: string;
  link: string;
  isRead: boolean;
  relatedResourceId: string;
  relatedResourceType: string;
  createdAt: string;
  emailStatus: 'PENDING' | 'SENT' | 'FAILED';
  emailSentAt: string | null;
  templateParams: Record<string, unknown>;
}

export interface ApiNotificationResponse {
  message: string;
  data: ApiNotification[];
  metadata: {
    total: number;
    unreadCount: number;
    page: number;
    limit: number;
    totalPages: number;
  };
}

export interface NotificationItem {
  id: string;
  type: NotificationItemType;
  userId: string;
  message: string;
  read: boolean;
  time: string;
  createdAt: string;
  actor: User;
  resource: Resource;
  vault: Vault;
  link: string;
}

export interface NotificationResponse {
  message: string;
  data: NotificationItem[];
  metadata: {
    total: number;
    unreadCount: number;
    page: number;
    limit: number;
    totalPages: number;
  };
}

export interface NotificationQueryParams {
  page?: number;
  limit?: number;
}
