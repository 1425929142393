import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DocumentationResponse } from '../types/master';

export const useGetDocumentData = (
  documentId: string | null
): UseQueryResult<DocumentationResponse, Error> => {
  const { accessToken } = useAuth();

  return useQuery<DocumentationResponse, Error>({
    queryKey: ['docInfo', documentId],
    queryFn: async (): Promise<DocumentationResponse> => {
      const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

      if (!accessToken || !documentId) {
        throw new Error('No access token available');
      }

      const response = await fetch(
        `${apiBaseUrl}/documents/select/${documentId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        // Immediately throw error for non-ok responses
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: DocumentationResponse = await response.json();
      return data;
    },
    enabled: !!documentId && !!accessToken,
    retry: false,
    staleTime: 30000,
    refetchOnWindowFocus: false,
  });
};

export default useGetDocumentData;
