import { Loader2, RefreshCw, Trash } from 'lucide-react';
import { useConfluenceManagement } from '@fluency/ui/hooks';
import ConfluenceIcon from './ConfluenceIcon';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@fluency/ui/components/ui/table';
import { Badge } from '@fluency/ui/components/ui/badge';
import { Button } from '@fluency/ui/components/ui/button';

export const IntegrationsSettings = () => {
  const {
    installConfluence,
    isInstalling,
    installError,
    connectionStatus,
    isLoadingConnectionStatus,
    connectionStatusError,
    removeConfluence,
    isRemoving,
    removeError,
  } = useConfluenceManagement();

  const handleInstall = () => {
    installConfluence();
  };

  const handleRemove = () => {
    removeConfluence();
  };

  const renderConnectionStatus = () => {
    if (isLoadingConnectionStatus) {
      return (
        <Badge variant="secondary" className="h-6">
          <RefreshCw className="w-3 h-3 mr-1 animate-spin" />
          Checking connection status
        </Badge>
      );
    }
    if (connectionStatusError) {
      return (
        <Badge variant="destructive" className="h-6">
          Error: {connectionStatusError.message}
        </Badge>
      );
    }
    if (connectionStatus && connectionStatus.status === 'connected') {
      return (
        <Badge className="bg-green-500 capitalize h-6">
          {connectionStatus.status}
        </Badge>
      );
    }
    return null;
  };

  const isConnected = connectionStatus?.status === 'connected';

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium mb-1">App Integrations</h2>
        <p className="text-sm text-muted-foreground">
          View and manage your third-party service connections
        </p>
      </div>

      <Card className="rounded-lg border">
        <CardHeader className="pb-3">
          <CardTitle className="text-base font-medium">
            Connected Services
          </CardTitle>
        </CardHeader>
        <CardContent className="p-0">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="py-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-6">
                      <div className="flex items-center gap-3">
                        <ConfluenceIcon className="h-6 w-6 text-[#0c66e4]" />
                        <div className="space-y-1">
                          <div className="font-medium">Confluence</div>
                          <div className="text-sm text-muted-foreground">
                            Connect to your Confluence workspace
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        {renderConnectionStatus()}
                        {installError && (
                          <Badge variant="destructive" className="h-6">
                            Installation error: {installError.message}
                          </Badge>
                        )}
                        {removeError && (
                          <Badge variant="destructive" className="h-6">
                            Removal error: {removeError.message}
                          </Badge>
                        )}
                      </div>
                    </div>
                    <div>
                      {isConnected ? (
                        <Button
                          onClick={handleRemove}
                          disabled={isRemoving}
                          variant="ghost"
                          size="sm"
                        >
                          {isRemoving ? (
                            <>
                              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                              Removing...
                            </>
                          ) : (
                            <Trash className="h-4 w-4" />
                          )}
                        </Button>
                      ) : !isLoadingConnectionStatus ? (
                        <Button
                          onClick={handleInstall}
                          disabled={isInstalling}
                          size="sm"
                        >
                          {isInstalling ? (
                            <>
                              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                              Opening Atlassian...
                            </>
                          ) : (
                            'Install Confluence'
                          )}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {isInstalling && (
        <div className="bg-muted/50 border rounded-lg p-4 text-sm text-muted-foreground flex items-center justify-center">
          <RefreshCw className="w-4 h-4 mr-2 animate-spin" />
          Please complete the authorization in the new tab. You may need to
          allow pop-ups for this site.
        </div>
      )}
    </div>
  );
};

export default IntegrationsSettings;
