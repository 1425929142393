import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { WorkosRole } from './types/WorkosRole';
import { ResourceType } from '../types/fga';

export enum RoleName {
  MANAGER = 'MANAGER',
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER',
}

interface DetailedPermissionInfo {
  effectiveRole: {
    role: RoleName | null;
    source: 'organisation' | 'direct' | 'team' | 'inherited' | null;
    details: {
      organisationRole?: WorkosRole;
      teamId?: string;
      teamName?: string;
      inheritedFromResource?: {
        id: string;
        type: ResourceType;
      };
    } | null;
  };
  allPermissions: {
    organisation: {
      role: WorkosRole;
      effectiveRole: RoleName | null;
    };
    direct: {
      roles: RoleName[];
    };
    team: Array<{
      teamId: string;
      teamName: string;
      roles: RoleName[];
    }>;
    inherited: Array<{
      sourceResourceId: string;
      sourceResourceType: ResourceType;
      roles: RoleName[];
    }>;
  };
}

interface DocumentPermissionsResult {
  permissions: DetailedPermissionInfo | undefined;
  isLoading: boolean;
  error: Error | null;
  role: RoleName | null | undefined;
  isManager: boolean;
  isEditor: boolean;
  isViewer: boolean;
}

export const useDocumentPermissions = (
  documentId?: string
): DocumentPermissionsResult => {
  const { accessToken } = useAuth();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const fetchPermissions = async (): Promise<DetailedPermissionInfo> => {
    if (!documentId) {
      throw new Error('Document ID is required');
    }

    const response = await fetch(
      `${apiBaseUrl}/fga/getUserResourcePermission?resourceId=${documentId}&resourceType=DOCUMENTATION`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch user permissions');
    }

    return response.json();
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['documentPermissions', documentId],
    queryFn: fetchPermissions,
    enabled: !!documentId && !!accessToken,
  });

  // Helper function to safely check role hierarchy
  const hasRole = (
    userRole: RoleName | null | undefined,
    requiredRole: RoleName
  ): boolean => {
    if (!userRole) return false;

    const roleHierarchy = {
      [RoleName.MANAGER]: 3,
      [RoleName.EDITOR]: 2,
      [RoleName.VIEWER]: 1,
    };

    return roleHierarchy[userRole] >= roleHierarchy[requiredRole];
  };

  const currentRole = data?.effectiveRole?.role;

  return {
    permissions: data,
    isLoading,
    error: error as Error | null,
    role: currentRole,
    isManager: hasRole(currentRole, RoleName.MANAGER),
    isEditor: hasRole(currentRole, RoleName.EDITOR),
    isViewer: hasRole(currentRole, RoleName.VIEWER),
  };
};
