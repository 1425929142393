import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider.js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { DocumentationResponse } from '../types/master.js';

async function autoUpdateImagePii(params: {
  imageKey: string;
  accessToken: string;
}): Promise<void> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const endpoint = '/screenshot/autoRedactScreenshot';
  const fullUrl = apiBaseUrl + endpoint;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${params.accessToken}`);

  const raw = JSON.stringify({
    screenshotKey: params.imageKey,
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  };

  const response = await fetch(fullUrl, requestOptions);
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      `HTTP error! status: ${response.status}, message: ${errorText}`
    );
  }
}

const useAutoImagePii = (documentationId: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();
  const { toast } = useToast();

  return useMutation<
    void,
    Error,
    { imageKey: string },
    { previousDocInfo?: DocumentationResponse }
  >({
    mutationFn: async (args) => {
      if (!accessToken) {
        throw new Error('No access token found');
      }
      await autoUpdateImagePii({
        imageKey: args.imageKey,
        accessToken,
      });
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(
        ['docInfo', documentationId],
        context?.previousDocInfo
      );
      toast({
        variant: 'destructive',
        title: 'Sorry - there was an error auto-redacting the image',
        description: 'Please try again later.',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['docInfo', documentationId],
      });
      toast({
        title: 'Success',
        description: 'Image auto-redacted successfully.',
      });
    },
  });
};

export default useAutoImagePii;
