import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { Input } from '@fluency/ui/components/ui/input';
import { Label } from '@fluency/ui/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import { Separator } from '@fluency/ui/components/ui/separator';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { useGetOrgUsers } from '@fluency/ui/hooks';
import {
  PermissionGateProvider,
  withPermissionGate,
} from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { PenSquare } from 'lucide-react';
import { useState } from 'react';

type Membership = {
  id: string;
  user: {
    id: string;
    firstName?: string;
    lastName?: string;
    email: string;
    emailVerified: boolean;
  };
  role: {
    slug: string;
  };
};

interface EditUserModalProps {
  membership: Membership;
}

export default withPermissionGate<EditUserModalProps>(EditUserModal, [
  'membership:update',
]);

function EditUserModal({ membership }: EditUserModalProps) {
  const {
    changeUserRole,
    isChangingRole,
    deleteMembership,
    isDeletingMembership,
  } = useGetOrgUsers();
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState(membership.role.slug);
  const [isConfirmingRemove, setIsConfirmingRemove] = useState(false);

  const handleUpdateRole = () => {
    changeUserRole(
      { membershipId: membership.id, newRole: role },
      {
        onSuccess: () => {
          toast({
            title: 'Updated role',
            description: `${getDisplayName(
              membership.user
            )} changed to ${role}`,
          });
          setOpen(false);
        },
        onError: (error) => {
          console.error('Failed to update role:', error);
          toast({
            title: 'Error',
            description: `Could not update role`,
          });
        },
      }
    );
  };

  const handleRemoveUser = () => {
    if (isConfirmingRemove) {
      deleteMembership(membership.id, {
        onSuccess: () => {
          toast({
            title: 'User Removed',
            description: `${getDisplayName(
              membership.user
            )} has been removed from the organisation.`,
          });
          setOpen(false);
        },
        onError: (error) => {
          console.error('Failed to remove user:', error);
          toast({
            title: 'Error',
            description: 'Could not remove user from the organisation.',
            variant: 'destructive',
          });
        },
      });
    } else {
      setIsConfirmingRemove(true);
    }
  };

  const cancelRemove = () => {
    setIsConfirmingRemove(false);
  };

  const getDisplayName = (user: Membership['user']) => {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return user.email;
  };

  const displayName = getDisplayName(membership.user);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <PenSquare className="h-5 w-5  hover:cursor-pointer" />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit User</DialogTitle>
          <DialogDescription>
            Change the role or remove {displayName} from the organisation.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-5 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input
              id="name"
              value={displayName}
              className="col-span-4 pointer-events-none"
              disabled={true}
            />
          </div>
          <div className="grid grid-cols-5 items-center gap-4">
            <Label htmlFor="role" className="text-right">
              Role
            </Label>
            <Select value={role} onValueChange={setRole}>
              <SelectTrigger className="col-span-4">
                <SelectValue placeholder="Select a role" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="admin">Admin</SelectItem>
                <SelectItem value="member">Member</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <Button
            variant="secondary"
            onClick={handleUpdateRole}
            disabled={isChangingRole || role === membership.role.slug}
          >
            {isChangingRole ? 'Updating...' : 'Update Role'}
          </Button>
        </div>
        <Separator />
        <DialogFooter>
          <PermissionGateProvider permissions={['membership:delete']}>
            <div className="w-full">
              {/* Remove User Section */}
              <div className="mb-4">
                <span className="font-semibold">Remove user</span>
                <p className="text-sm mb-4">
                  Removing a user will revoke their access to the organisation.
                  This action cannot be undone.
                </p>
                {!isConfirmingRemove ? (
                  <Button
                    variant="secondary"
                    onClick={handleRemoveUser}
                    className="w-full"
                    disabled={isDeletingMembership}
                  >
                    {isDeletingMembership ? 'Removing...' : 'Remove User'}
                  </Button>
                ) : (
                  <div className="flex gap-2">
                    <Button
                      variant="destructive"
                      onClick={handleRemoveUser}
                      className="flex-1"
                      disabled={isDeletingMembership}
                    >
                      {isDeletingMembership ? 'Removing...' : 'Confirm Remove'}
                    </Button>
                    <Button
                      variant="outline"
                      onClick={cancelRemove}
                      className="flex-1"
                      disabled={isDeletingMembership}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </PermissionGateProvider>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
