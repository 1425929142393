import { formatDistanceToNow } from 'date-fns';
import {
  ApiNotification,
  ApiNotificationResponse,
  NotificationItem,
  NotificationResponse,
} from '../types/notifications.type';

function parseMarkdownMessage(message: string | null): {
  actorName: string;
  resourceName: string;
  vaultName?: string;
} {
  if (!message) {
    return {
      actorName: 'Unknown User',
      resourceName: 'Unknown Resource',
    };
  }

  try {
    // Extract information from markdown message with null checks
    const actorMatch = message.match(/\*\*(?:By|From):\*\* ([^\n]+)/);
    const resourceMatch = message.match(/\*\*Resource:\*\* ([^\n]+)/);

    return {
      actorName: actorMatch?.[1] || 'Unknown User',
      resourceName: resourceMatch?.[1] || 'Unknown Resource',
    };
  } catch (error) {
    console.error('Error parsing notification message:', error);
    return {
      actorName: 'Unknown User',
      resourceName: 'Unknown Resource',
    };
  }
}

export function transformApiNotification(
  apiNotification: ApiNotification | null
): NotificationItem {
  if (!apiNotification) {
    throw new Error('Invalid notification data received');
  }

  try {
    const { actorName, resourceName } = parseMarkdownMessage(
      apiNotification.message || null
    );

    // Extract vault ID from link with null check
    const vaultId = apiNotification.link?.split('/')?.[2] || 'default-vault-id';

    // Ensure relatedResourceType is a string before calling toLowerCase
    const resourceType = (
      apiNotification.relatedResourceType || 'document'
    ).toLowerCase();

    return {
      id: apiNotification.id || '',
      type: apiNotification.type,
      userId: apiNotification.userId || '',
      message: apiNotification.message || '',
      read: apiNotification.isRead || false,
      time: apiNotification.createdAt
        ? formatDistanceToNow(new Date(apiNotification.createdAt), {
            addSuffix: true,
          })
        : 'recently',
      createdAt: apiNotification.createdAt || new Date().toISOString(),
      actor: {
        id: 'unknown',
        name: actorName,
      },
      resource: {
        id: apiNotification.relatedResourceId || '',
        name: resourceName,
        type:
          resourceType === 'document' || resourceType === 'workflow'
            ? resourceType
            : 'document',
      },
      vault: {
        id: vaultId,
        name: 'My Vault',
      },
      link: apiNotification.link || '',
    };
  } catch (error) {
    console.error('Error transforming notification:', error);
    throw new Error(`Failed to transform notification: ${error}`);
  }
}

export function transformApiResponse(
  apiResponse: ApiNotificationResponse | null
): NotificationResponse {
  if (!apiResponse) {
    throw new Error('Invalid API response received');
  }

  try {
    return {
      message: apiResponse.message || '',
      data: (apiResponse.data || [])
        .filter((notification) => notification !== null)
        .map(transformApiNotification),
      metadata: {
        total: apiResponse.metadata?.total || 0,
        unreadCount: apiResponse.metadata?.unreadCount || 0,
        page: apiResponse.metadata?.page || 1,
        limit: apiResponse.metadata?.limit || 10,
        totalPages: apiResponse.metadata?.totalPages || 1,
      },
    };
  } catch (error) {
    console.error('Error transforming API response:', error);
    throw new Error(`Failed to transform API response: ${error}`);
  }
}
