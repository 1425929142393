import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@fluency/ui/components/ui/use-toast';
import type {
  UpdateTeamResponse,
  UpdateTeamVariables,
  Team,
  TeamApiResponse,
} from '../types/types';

export function useUpdateTeam() {
  const { accessToken, orgId } = useAuth();
  const queryClient = useQueryClient();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  return useMutation<
    UpdateTeamResponse,
    Error,
    UpdateTeamVariables,
    { previousTeams?: Team[]; previousTeam?: TeamApiResponse }
  >({
    mutationKey: ['updateTeam'],
    mutationFn: async ({ teamId, teamName, teamDescription }) => {
      const response = await fetch(`${apiBaseUrl}/teams/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ teamId, teamName, teamDescription }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.json();
    },
    onMutate: async ({ teamId, teamName, teamDescription }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['teams', orgId] });
      await queryClient.cancelQueries({ queryKey: ['team', teamId] });

      // Snapshot the previous values
      const previousTeams = queryClient.getQueryData<Team[]>(['teams', orgId]);
      const previousTeam = queryClient.getQueryData<TeamApiResponse>([
        'team',
        teamId,
      ]);

      // Optimistically update the teams list
      queryClient.setQueryData<Team[]>(
        ['teams', orgId],
        (oldTeams) =>
          oldTeams?.map((team) =>
            team.teamId === teamId
              ? {
                  ...team,
                  teamName: teamName ?? team.teamName,
                  teamDescription: teamDescription ?? team.teamDescription,
                }
              : team
          )
      );

      // Optimistically update the individual team data
      queryClient.setQueryData<TeamApiResponse>(['team', teamId], (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          team: {
            ...oldData.team,
            teamName: teamName ?? oldData.team.teamName,
            teamDescription: teamDescription ?? oldData.team.teamDescription,
          },
        };
      });

      return { previousTeams, previousTeam };
    },
    onError: (_error, variables, context) => {
      // Revert the optimistic updates on error
      if (context?.previousTeams) {
        queryClient.setQueryData(['teams', orgId], context.previousTeams);
      }
      if (context?.previousTeam) {
        queryClient.setQueryData(
          ['team', variables.teamId],
          context.previousTeam
        );
      }

      // Invalidate the queries to refetch the latest data
      queryClient.invalidateQueries({ queryKey: ['teams', orgId] });
      queryClient.invalidateQueries({ queryKey: ['team', variables.teamId] });

      toast({
        variant: 'destructive',
        title: 'Something went wrong.',
        description: 'There was a problem updating the team. Please try again.',
      });
    },
    onSuccess: (data) => {
      toast({
        variant: 'default',
        title: 'Success',
        description: data.message || 'Team updated successfully.',
      });
    },
    onSettled: (_data, _error, variables) => {
      // Always invalidate the queries after settling to ensure data consistency
      queryClient.invalidateQueries({ queryKey: ['teams', orgId] });
      queryClient.invalidateQueries({ queryKey: ['team', variables.teamId] });
    },
  });
}
