import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DocumentationResponse,
  ScreenshotType,
  Step,
} from '../types/master.ts';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider.tsx';

interface CropInfo {
  xCoordinate: number | null;
  yCoordinate: number | null;
}

interface AnnotationInfo {
  annotationId: string;
  x1Coordinate?: number;
  y1Coordinate?: number;
  x2Coordinate?: number;
  y2Coordinate?: number;
  color?: string;
  shapeType?: string;
}

interface ScreenshotUpdate {
  screenshotId: string;
  cropInfo?: CropInfo;
  hidden?: boolean;
  annotation?: boolean;
  annotations?: AnnotationInfo[];
}

interface UpdateScreenshotProps {
  screenshotsToUpdate: ScreenshotUpdate[];
}

async function updateScreenshots(
  screenshotsToUpdate: ScreenshotUpdate[],
  accessToken: string
): Promise<void> {
  const endpoint = `/screenshot/update`;
  const apiUrl = import.meta.env.VITE_SERVER_API_URL;
  const fullUrl = apiUrl + endpoint;

  console.log({ screenshotsToUpdate });

  const response = await fetch(fullUrl, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ screenshotsToUpdate }),
  });

  if (!response.ok) {
    const body = await response.json();
    console.error('Error updating screenshots', body);
    throw new Error(`HTTP error! status: ${response.status}`);
  }
}

interface UseUpdateScreenshotProps {
  documentationId: string;
}

const useUpdateScreenshot = ({ documentationId }: UseUpdateScreenshotProps) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation<
    void,
    Error,
    UpdateScreenshotProps,
    { previousDocInfo: DocumentationResponse | undefined }
  >({
    mutationFn: ({ screenshotsToUpdate }) => {
      if (!accessToken) {
        throw new Error('Access token is missing');
      }
      return updateScreenshots(screenshotsToUpdate, accessToken);
    },
    mutationKey: ['screenshot-update'],
    onMutate: async ({ screenshotsToUpdate }) => {
      await queryClient.cancelQueries({
        queryKey: ['docInfo', documentationId],
      });

      const previousDocInfo = queryClient.getQueryData<DocumentationResponse>([
        'docInfo',
        documentationId,
      ]);

      if (previousDocInfo) {
        queryClient.setQueryData<DocumentationResponse>(
          ['docInfo', documentationId],
          (old) => {
            if (!old) return old;
            const updatedSteps = old.documentation.steps.map((step: Step) => ({
              ...step,
              screenshots: step.screenshots.map(
                (screenshot: ScreenshotType) => {
                  const update = screenshotsToUpdate.find(
                    (s) => s.screenshotId === screenshot.screenshotId
                  );
                  if (update) {
                    const updatedScreenshot = { ...screenshot };

                    // Only update properties that are explicitly provided
                    if (update.hidden !== undefined)
                      updatedScreenshot.hidden = update.hidden;
                    if (update.annotation !== undefined)
                      updatedScreenshot.annotation = update.annotation;
                    if (update.annotations) {
                      updatedScreenshot.annotations =
                        screenshot.annotations.map((existingAnnotation) => {
                          const updatedAnnotation = update.annotations?.find(
                            (a) =>
                              a.annotationId ===
                              existingAnnotation.screenshotAnnotationId
                          );
                          if (updatedAnnotation) {
                            return {
                              ...existingAnnotation,
                              shapeType:
                                (updatedAnnotation.shapeType as
                                  | 'RECTANGLE'
                                  | 'TEXT'
                                  | 'CIRCLE'
                                  | 'ARROW'
                                  | 'LINE') ?? existingAnnotation.shapeType,
                              x1Coordinate:
                                updatedAnnotation.x1Coordinate ??
                                existingAnnotation.x1Coordinate,
                              y1Coordinate:
                                updatedAnnotation.y1Coordinate ??
                                existingAnnotation.y1Coordinate,
                              x2Coordinate:
                                updatedAnnotation.x2Coordinate ??
                                existingAnnotation.x2Coordinate,
                              y2Coordinate:
                                updatedAnnotation.y2Coordinate ??
                                existingAnnotation.y2Coordinate,
                              color:
                                updatedAnnotation.color ??
                                existingAnnotation.color,
                            };
                          }
                          return existingAnnotation;
                        });

                      // Add any new annotations
                      const newAnnotations = update.annotations.filter(
                        (a) =>
                          !screenshot.annotations.some(
                            (existing) =>
                              existing.screenshotAnnotationId === a.annotationId
                          )
                      );
                      updatedScreenshot.annotations = [
                        ...updatedScreenshot.annotations,
                        ...newAnnotations.map((newAnnotation) => ({
                          screenshotAnnotationId: newAnnotation.annotationId,
                          screenshotId: screenshot.screenshotId,
                          shapeType: newAnnotation.shapeType as
                            | 'RECTANGLE'
                            | 'TEXT'
                            | 'CIRCLE'
                            | 'ARROW'
                            | 'LINE',
                          x1Coordinate: newAnnotation.x1Coordinate ?? 0,
                          y1Coordinate: newAnnotation.y1Coordinate ?? 0,
                          x2Coordinate: newAnnotation.x2Coordinate ?? 0,
                          y2Coordinate: newAnnotation.y2Coordinate ?? 0,
                          annotationIndex: 0,
                          color: newAnnotation.color ?? '#FF0000',
                          createdAt: new Date().toISOString(),
                          updatedAt: new Date().toISOString(),
                        })),
                      ];
                    }

                    return updatedScreenshot;
                  }
                  return screenshot;
                }
              ),
            }));
            return {
              ...old,
              documentation: {
                ...old.documentation,
                steps: updatedSteps,
              },
            };
          }
        );
      }

      return { previousDocInfo };
    },
    onError: (err, _newScreenshots, context) => {
      if (context?.previousDocInfo) {
        queryClient.setQueryData<DocumentationResponse>(
          ['docInfo', documentationId],
          context.previousDocInfo
        );
      }
      console.error('Error updating screenshot', err);
      toast({
        title: 'Error',
        description: 'Failed to update screenshot. Please try again.',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['docInfo', documentationId] });
      queryClient.invalidateQueries({
        queryKey: ['docInfo-screenshots', documentationId],
      });
    },
  });
};

export default useUpdateScreenshot;
