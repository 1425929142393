import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@fluency/ui/components/ui/card';
import PaymentMethodCard from './PaymentMethodCard.js';
import BillingInformationCard from './BillingInformationCard.js';

export function PaymentMethod() {
  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-md font-medium">Billing</CardTitle>
        <CardDescription>Change how you pay for your plan.</CardDescription>
      </CardHeader>
      <CardContent>
        <PaymentMethodCard />
        <BillingInformationCard />
      </CardContent>
    </Card>
  );
}
