import { transitionStyles } from '../styles';
import { CropData } from '../types';

export const hasCropData = (crop: CropData): boolean => {
  return (
    crop.x1 !== null && crop.x2 !== null && crop.y1 !== null && crop.y2 !== null
  );
};

export const getScaledStyle = (naturalWidth: number, maxScale: number) => {
  return {
    display: 'block',
    maxWidth: `${naturalWidth * maxScale}px`,
    width: '100%',
    ...transitionStyles.base,
  };
};
