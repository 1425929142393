import { useGetTeams } from '@fluency/ui/hooks';

export const useTeamName = () => {
  const { data: TeamData, isLoading } = useGetTeams();

  const getTeamName = (id: string): string => {
    if (isLoading) {
      return '';
    }
    if (id === 'manage') {
      return 'Manage Team';
    }

    const team = TeamData?.find((team) => team.teamId === id);
    const result = team?.teamName || `team ${id}`;
    return result;
  };

  return { getTeamName, isLoading };
};
