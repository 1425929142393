import React from 'react';
import { DashboardShell } from '@fluency/ui/components';
import { PermissionGateProvider } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { Link } from '@tanstack/react-router';
import {
  Building2,
  UserCog,
  Bell,
  Wallet,
  Users,
  Puzzle,
  Shield,
  Paintbrush,
  UserPlus,
  Trash2,
} from 'lucide-react';

import AdvancedSettings from './components/Advanced/AdvancedSettings';
import Billing from './components/Billing/Billing';
import Branding from './components/Branding';
import { GeneralSettings } from './components/GeneralCard/GeneralCard';
import { NotificationPreferences } from './components/NotificationPreferences';
import { IntegrationsSettings } from './components/Integrations';
import { MembersSettings } from './components/Members';
import PIICard from './components/PII/PII';
import { cn } from '@fluency/ui/lib/utils';
import TeamSettings from './components/Teams';
import { getColumns } from './components/Teams/data/columns';
import { useFeatureFlags } from '@fluency/ui/providers/feature-flags/FeatureFlagProvider';
import { FeatureFlags } from '@fluency/ui/providers/feature-flags/types/FeatureFlag.type';

interface UserProfileProps {
  setting: string;
}

export interface NavItem {
  to: string;
  setting: string;
  label: string;
  icon: React.ElementType;
  permissions: string[];
  type: 'account' | 'preferences' | 'team' | 'admin';
  featureFlag?: string;
}

export const navItems: NavItem[] = [
  // Account Settings
  {
    to: '/profile/$setting',
    setting: 'general',
    label: 'General',
    icon: UserCog,
    permissions: ['user:read'],
    type: 'account',
  },
  {
    to: '/profile/$setting',
    setting: 'notifications',
    label: 'Notifications',
    icon: Bell,
    permissions: ['user:read'],
    type: 'preferences',
  },
  {
    to: '/profile/$setting',
    setting: 'integrations',
    label: 'Integrations',
    icon: Puzzle,
    permissions: ['user:update'],
    type: 'preferences',
  },
  // Team Management
  {
    to: '/profile/$setting',
    setting: 'members',
    label: 'Members',
    icon: UserPlus,
    permissions: ['org:update'],
    type: 'team',
  },
  {
    to: '/profile/$setting',
    setting: 'teams',
    label: 'Teams',
    icon: Users,
    permissions: ['org:update'],
    type: 'team',
  },
  // Admin Controls
  {
    to: '/profile/$setting',
    setting: 'subscription',
    label: 'Subscription',
    icon: Wallet,
    permissions: ['subscription:read'],
    type: 'admin',
  },
  {
    to: '/profile/$setting',
    setting: 'pii',
    label: 'PII Protection',
    icon: Shield,
    permissions: ['org:update'],
    type: 'admin',
    featureFlag: 'auto-pii',
  },
  {
    to: '/profile/$setting',
    setting: 'branding',
    label: 'Branding',
    icon: Paintbrush,
    permissions: ['org:update'],
    type: 'admin',
    featureFlag: 'branding',
  },
  {
    to: '/profile/$setting',
    setting: 'danger',
    label: 'Danger Zone',
    icon: Trash2,
    permissions: ['org:update'],
    type: 'admin',
  },
] as const;

interface NavSectionProps {
  title: string;
  icon: React.ElementType;
  items: NavItem[];
  currentSetting: string;
}

const NavSection: React.FC<NavSectionProps> = ({
  title,
  items,
  currentSetting,
}) => {
  const { isFeatureEnabled } = useFeatureFlags();

  const filteredItems = items.filter(
    (item) =>
      !item.featureFlag ||
      isFeatureEnabled(item.featureFlag as keyof FeatureFlags)
  );

  if (filteredItems.length === 0) return null;

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2 px-2 py-2">
        <span className="text-xs font-medium uppercase tracking-wider text-slate-500">
          {title}
        </span>
      </div>
      <nav className="grid gap-1">
        {filteredItems.map((item) => (
          <PermissionGateProvider
            key={item.setting}
            permissions={item.permissions}
          >
            <Link
              to={item.to}
              params={{ setting: item.setting }}
              className={cn(
                'flex items-center gap-2 rounded-md px-2 py-1.5',
                'text-sm font-normal transition-colors',
                item.setting === 'danger'
                  ? cn(
                      'text-red-600 hover:bg-red-50',
                      currentSetting === item.setting ? 'bg-red-50' : ''
                    )
                  : cn(
                      'hover:bg-accent hover:text-accent-foreground',
                      currentSetting === item.setting
                        ? 'bg-accent text-accent-foreground'
                        : 'text-foreground'
                    )
              )}
            >
              <item.icon
                className={cn(
                  'h-4 w-4',
                  item.setting === 'danger' ? 'text-red-600' : ''
                )}
              />
              {item.label}
            </Link>
          </PermissionGateProvider>
        ))}
      </nav>
    </div>
  );
};

const Settings: React.FC<UserProfileProps> = ({ setting }) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const accountItems = navItems.filter((item) => item.type === 'account');
  const preferenceItems = navItems.filter(
    (item) => item.type === 'preferences'
  );
  const teamItems = navItems.filter((item) => item.type === 'team');
  const adminItems = navItems.filter((item) => item.type === 'admin');

  // Update filtering to use isFeatureEnabled
  const filteredTeamItems = teamItems.filter(
    (item) =>
      !item.featureFlag ||
      isFeatureEnabled(item.featureFlag as keyof FeatureFlags)
  );
  const filteredAdminItems = adminItems.filter(
    (item) =>
      !item.featureFlag ||
      isFeatureEnabled(item.featureFlag as keyof FeatureFlags)
  );

  const columns = React.useMemo(() => getColumns(), []);

  const shouldShowPII = isFeatureEnabled('auto-pii');
  const shouldShowBranding = isFeatureEnabled('branding');

  return (
    <DashboardShell>
      <main className="flex h-full border-t">
        <div className="relative flex-shrink-0 border-r">
          <div className="sticky top-0 w-[240px] h-[calc(100vh-65px-10px)] overflow-y-auto px-2 py-4">
            <div className="space-y-6">
              <NavSection
                title="Account"
                icon={UserCog}
                items={accountItems}
                currentSetting={setting}
              />
              <NavSection
                title="Preferences"
                icon={Settings}
                items={preferenceItems}
                currentSetting={setting}
              />
              <PermissionGateProvider permissions={['org:read']}>
                {filteredTeamItems.length > 0 && (
                  <NavSection
                    title="Organisation"
                    icon={Users}
                    items={teamItems}
                    currentSetting={setting}
                  />
                )}
                {filteredAdminItems.length > 0 && (
                  <NavSection
                    title="Administration"
                    icon={Building2}
                    items={adminItems}
                    currentSetting={setting}
                  />
                )}
              </PermissionGateProvider>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div className="px-8 py-6">
            <div className="mx-auto max-w-5xl space-y-6">
              {setting === 'general' && <GeneralSettings />}
              {setting === 'notifications' && <NotificationPreferences />}
              {setting === 'integrations' && <IntegrationsSettings />}
              <PermissionGateProvider permissions={['org:read']}>
                {setting === 'members' && <MembersSettings />}
              </PermissionGateProvider>
              <PermissionGateProvider permissions={['subscription:read']}>
                {setting === 'subscription' && <Billing />}
              </PermissionGateProvider>
              <PermissionGateProvider permissions={['org:update']}>
                {setting === 'pii' && shouldShowPII && <PIICard />}
              </PermissionGateProvider>
              <PermissionGateProvider permissions={['org:update']}>
                {setting === 'teams' && <TeamSettings columns={columns} />}
              </PermissionGateProvider>
              <PermissionGateProvider permissions={['org:update']}>
                {setting === 'branding' && shouldShowBranding && <Branding />}
              </PermissionGateProvider>
              <PermissionGateProvider permissions={['org:update']}>
                {setting === 'danger' && <AdvancedSettings />}
              </PermissionGateProvider>
            </div>
          </div>
        </div>
      </main>
    </DashboardShell>
  );
};

export default Settings;
