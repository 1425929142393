import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useNavigate } from '@tanstack/react-router';

interface CreateDocumentPayload {
  duration: number;
  name: string;
  description: string;
  createdDate: string;
  vaultId: string;
}

interface CreateDocumentResponseData {
  documentationId: string;
  documentationName: string;
  createdDate: string;
  duration: number;
  totalSteps: number | null;
  approved: boolean | null;
  description: string;
  status: string | null;
  visibility: string | null;
  rawData: any | null;
  shareType: string;
  createdBy: string;
  tenantId: string;
  shareId: string;
}

interface CreateDocumentResponse {
  message: string;
  document: CreateDocumentResponseData;
}

interface CreateEmptyDocOptions {
  disableRedirect?: boolean;
}

async function createEmptyDoc(
  payload: CreateDocumentPayload,
  token: string
): Promise<CreateDocumentResponse> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const endpoint = `${apiBaseUrl}/documents/create`;

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(
      errorData.message || `HTTP error! status: ${response.status}`
    );
  }

  return (await response.json()) as CreateDocumentResponse;
}

const useCreateEmptyDoc = (options: CreateEmptyDocOptions = {}) => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { disableRedirect = false } = options;

  return useMutation<CreateDocumentResponse, Error, CreateDocumentPayload>({
    mutationFn: (payload) => createEmptyDoc(payload, accessToken ?? ''),
    onSuccess: (data) => {
      toast({
        variant: 'default',
        title: 'Success',
        description: data.message || 'Empty document created successfully.',
      });

      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['documentation'] });

      // Only navigate if redirect is not disabled
      if (!disableRedirect) {
        navigate({
          to: '/document/$id',
          params: { id: data.document.documentationId },
        });
      }
    },
    onError: (error) => {
      toast({
        variant: 'destructive',
        title: 'Error creating document',
        description:
          error.message || 'An unexpected error occurred. Please try again.',
      });
    },
  });
};

export default useCreateEmptyDoc;
