import React, { useEffect, useRef } from 'react';
import { Input } from '@fluency/ui/components/ui/input';
import { Textarea } from '@fluency/ui/components/ui/textarea';
import { Button } from '@fluency/ui/components/ui/button';
import { PencilLine } from 'lucide-react';
import {
  usePermissionGate,
  PermissionGateProvider,
} from '@fluency/ui/providers/permissions/PermissionGateProvider';

import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const titleSchema = z.object({
  value: z
    .string()
    .min(3, 'Title must be at least 3 characters.')
    .max(60, 'Title must be less than 60 characters.')
    .regex(/^[\p{L}\p{N}\p{P}\p{Zs}]*$/u, 'Title contains invalid characters.'),
});

const contentSchema = z.object({
  value: z
    .string()
    .max(500, 'Description must be less than 500 characters.')
    .min(3, 'Description must be at least 3 characters.')
    .regex(
      /^[\p{L}\p{N}\p{P}\p{Zs}]*$/u,
      'Description contains invalid characters.'
    ),
});

interface EditableInputProps {
  isEdit: boolean;
  currentValue: string;
  updateDoc: (value: string) => void;
  setCurrentValue: (value: string) => void;
  setIsEdit: (edit: boolean) => void;
  isTitle: boolean;
  isMostRecentVersion: boolean;
}

const EditableInput: React.FC<EditableInputProps> = ({
  isEdit,
  currentValue,
  updateDoc,
  setCurrentValue,
  setIsEdit,
  isTitle,
  isMostRecentVersion,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { hasPermissions } = usePermissionGate();

  const schema = isTitle ? titleSchema : contentSchema;

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      value: currentValue,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = (data: { value: string }) => {
    if (data.value !== currentValue) {
      updateDoc(data.value);
      setCurrentValue(data.value);
    }
    setIsEdit(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isEdit &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        handleSubmit(onSubmit)();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEdit, handleSubmit, onSubmit]);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (isTitle && e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(onSubmit)();
    } else if (!isTitle && e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(onSubmit)();
    } else if (e.key === 'Escape') {
      setIsEdit(false);
      form.reset({ value: currentValue });
    }
  };

  useEffect(() => {
    if (isEdit) {
      form.reset({ value: currentValue });
    }
  }, [isEdit, currentValue, form]);

  const renderText = () => {
    const className = `font-${
      isTitle ? 'medium text-lg' : 'normal text-md mt-1'
    } flex items-center cursor-pointer break-all`;
    const content = isTitle ? (
      <h2 className={className}>{currentValue}</h2>
    ) : (
      <p className={`${className} whitespace-pre-wrap text-sm`}>
        {currentValue}
      </p>
    );

    return (
      <div
        onClick={
          isMostRecentVersion && hasPermissions(['documents:update'])
            ? () => setIsEdit(true)
            : undefined
        }
      >
        {content}
      </div>
    );
  };

  return (
    <div ref={containerRef} className="flex items-start gap-2">
      {isEdit ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex items-start gap-2 w-full"
        >
          {isTitle ? (
            <div className="flex flex-col gap-2">
              <Input
                {...register('value')}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              {errors.value && (
                <p className="text-red-500 mt-1 font-semibold w-96">
                  {errors.value.message}
                </p>
              )}
            </div>
          ) : (
            <div className="w-full">
              <Textarea
                {...register('value')}
                onKeyDown={handleKeyDown}
                autoFocus
                rows={4}
                className="w-full"
              />
              {errors.value && (
                <p className="text-red-500 mt-1 font-semibold text-xs">
                  {errors.value.message}
                </p>
              )}
            </div>
          )}
          <div className="print:hidden flex ml-2 gap-2">
            <Button type="submit" variant="default" size="sm">
              Save
            </Button>
            <Button
              onClick={() => {
                setIsEdit(false);
                form.reset({ value: currentValue });
              }}
              variant="outline"
              size="sm"
            >
              Cancel
            </Button>
          </div>
        </form>
      ) : (
        <>
          {renderText()}
          <PermissionGateProvider permissions={['documents:update']}>
            {isMostRecentVersion && (
              <span className="print:hidden">
                <Button
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  className="h-7 w-7 text-gray-400"
                  variant="ghost"
                  size="icon"
                >
                  <PencilLine className="h-4 w-4 " />
                </Button>
              </span>
            )}
          </PermissionGateProvider>
        </>
      )}
    </div>
  );
};

export default EditableInput;
