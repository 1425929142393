import { useQuery } from '@tanstack/react-query';

export interface UserInfo {
  object: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface Annotation {
  screenshotAnnotationId: string;
  screenshotId: string;
  shapeType: 'CIRCLE' | 'RECTANGLE' | 'LINE' | 'ARROW';
  x1Coordinate: number;
  y1Coordinate: number;
  x2Coordinate: number | null;
  y2Coordinate: number | null;
  annotationIndex: number | null;
  color: string;
}

export interface Screenshot {
  screenshotId: string;
  screenshotStepNumber: number;
  uploadStatus: string;
  screenshotKey: string;
  x1Coordinate: number | null;
  y1Coordinate: number | null;
  x2Coordinate: number | null;
  y2Coordinate: number | null;
  isCropped: boolean;
  hidden: boolean;
  annotation: boolean;
  stepId: string;
  annotations: Annotation[];
  downloadUrl: string;
}

export interface Step {
  stepId: string;
  stepNumber: number;
  stepDescription: string;
  stepType: string;
  documentationId: string;
  screenshots: Screenshot[];
}

export interface SharedDocument {
  documentationId: string;
  documentationName: string;
  createdDate: string;
  duration: number;
  totalSteps: number;
  description: string;
  shareType: string;
  createdBy: string;
  tenantId: string;
  shareId: string;
  userInfo: UserInfo[];
  steps: Step[];
}

interface DocInfo {
  message: string;
  success: boolean;
  sharedDocument: SharedDocument;
}

const apiUrl = import.meta.env.VITE_SERVER_API_URL;

const fetchData = async (documentId: string) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
  };

  try {
    const response = await fetch(
      `${apiUrl}/documents/publicShareDocument/${documentId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log(result);
    return result as DocInfo;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message || 'An unknown error occurred');
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

export const useGetPublicDocs = (documentId: string) => {
  return useQuery<DocInfo, Error>({
    queryKey: ['get-public-docs', documentId],
    queryFn: () => fetchData(documentId),
  });
};

export default useGetPublicDocs;
