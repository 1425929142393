import { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { ArrowLeft } from 'lucide-react';
import logo from '@fluency/ui/assets/icon-blue.svg';
import { Button } from '../ui/button';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);

    Sentry.captureException(error);
  }

  public render() {
    if (this.state.hasError) {
      return (
        this.props.fallback || (
          <div className="flex flex-col items-center justify-center min-h-screen w-full bg-white">
            <img src={logo} className="h-14 w-14 mb-6" alt="Fluency Logo" />
            <div className="border rounded-lg p-8 max-w-md w-full">
              <h1 className="text-2xl font-bold mb-4">Something went wrong!</h1>
              <p className="text-gray-600 mb-6">
                We're sorry, but something went wrong. Please try refreshing the
                page or contact support if the problem persists.
              </p>
              <Button
                onClick={() => window.location.reload()}
                className="w-full mb-4 text-md"
                variant="outline"
              >
                Refresh Page
              </Button>
            </div>
            <Button
              variant="ghost"
              className="mt-6 flex items-center text-sm text-gray-600"
              onClick={() => (window.location.href = '/login')}
            >
              <ArrowLeft className="w-4 h-4 mr-1" /> Sign in with a different
              account
            </Button>
          </div>
        )
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
