import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { Input } from '@fluency/ui/components/ui/input';
import { Label } from '@fluency/ui/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import { useSubscription } from '@fluency/ui/hooks';
import useOrgInvites from '@fluency/ui/hooks/useOrgInvites';
import * as Sentry from '@sentry/react';
import { UserPlus } from 'lucide-react';
import { useState } from 'react';

export default function InviteUsers() {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('member');
  const [isOpen, setIsOpen] = useState(false);
  const { subscriptionStatus } = useSubscription();

  const usage = subscriptionStatus?.usage || { availableSeats: { creator: 0 } };
  const { invites, sendInvite } = useOrgInvites();

  // Calculate pending invites
  const pendingInvitesCount = invites.filter(
    (invite) => invite.state === 'pending'
  ).length;

  // Adjust available seats
  const seatsAvailable = usage.availableSeats.creator - pendingInvitesCount;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (seatsAvailable <= 0) {
      // Optionally display a message to the user
      return;
    }
    try {
      await sendInvite({ email, role });
      setIsOpen(false);
      setEmail('');
      setRole('member');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button className="gap-2" size="sm" disabled={seatsAvailable <= 0}>
          <UserPlus size={18} />
          Invite User{' '}
          {seatsAvailable > 0
            ? `(${seatsAvailable} ${
                seatsAvailable === 1 ? 'seat' : 'seats'
              } available)`
            : '(No seats available)'}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>
              Invite User{' '}
              {seatsAvailable > 0
                ? `(${seatsAvailable} ${
                    seatsAvailable === 1 ? 'seat' : 'seats'
                  } available)`
                : '(No seats available)'}
            </DialogTitle>
            <DialogDescription>
              Invite a new user to your organisation. They will receive an email
              with instructions to join.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right">
                Email
              </Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="col-span-3"
                required
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="role" className="text-right">
                Role
              </Label>
              <Select value={role} onValueChange={setRole}>
                <SelectTrigger className="col-span-3">
                  <SelectValue placeholder="Select a role" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="admin">Admin</SelectItem>
                  <SelectItem value="member">Member</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <DialogFooter>
            <Button type="submit" disabled={seatsAvailable <= 0}>
              Send Invite
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
