import { useState } from 'react';
import { Download, Home, RefreshCw, Settings } from 'lucide-react';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarTrigger,
} from '@fluency/ui/components/ui/sidebar';
import useGetVaults from '@fluency/ui/features/Home/hooks/useGetVaults';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useElectron } from '@fluency/ui/providers/electron/ElectronContext';
import { useLocation, useNavigate } from '@tanstack/react-router';
import logo from '@fluency/ui/assets/icon-blue.svg';
import { ReactNode } from 'react';
import { VaultIcon } from './VaultIcon';
import UserDropdown from './UserDropdown';
import TrialNotice from './TrialNotice';
import Welcome from './Welcome';
import ExtensionStatusButton from '../ExtensionStatusButton';
import DynamicBreadcrumbs from './breadcrumb/Breadcrumb';
import SidebarRecord from './SidebarRecord';
import { Separator } from '../ui/separator';
import NotificationPopup from '../../features/Notifications/components/NotificationPopup';

const AppSidebar = ({ children }: { children: ReactNode }) => {
  const [isWelcomeOpen, setIsWelcomeOpen] = useState(false);
  const { data: vaultsData, isLoading } = useGetVaults();
  const { isAuthenticated, organisationName, role } = useAuth();
  const { isElectron } = useElectron();
  const navigate = useNavigate();
  const location = useLocation();

  // Helper function to check if a path is active
  const isPathActive = (path: string) => {
    if (path === '/home' && location.pathname === '/home') {
      return true;
    }
    return location.pathname.startsWith(path) && path !== '/home';
  };

  const renderVaultList = () => {
    if (isLoading) {
      return (
        <SidebarMenuItem>
          <SidebarMenuButton>
            <RefreshCw className="h-4 w-4 animate-spin" />
            <span>Loading vaults...</span>
          </SidebarMenuButton>
        </SidebarMenuItem>
      );
    }

    return vaultsData?.vaults.map((vault) => (
      <SidebarMenuItem key={vault.id}>
        <SidebarMenuButton
          asChild
          isActive={isPathActive(`/vault/${vault.id}`)}
        >
          <div
            onClick={() => {
              navigate({
                to: '/vault/$id',
                params: { id: vault.id },
                replace: true,
              });
            }}
          >
            <VaultIcon className="h-5 w-5" strokeWidth={1.2} />
            <span>{vault.name}</span>
          </div>
        </SidebarMenuButton>
      </SidebarMenuItem>
    ));
  };

  return (
    <>
      <Sidebar variant="inset" className="z-0">
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton size="lg" asChild>
                <div
                  className="hover:cursor-pointer"
                  onClick={() => {
                    navigate({
                      to: '/home',
                      replace: true,
                    });
                  }}
                >
                  <img src={logo} className="h-10 w-10" alt="Fluency Logo" />
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">
                      {organisationName}
                    </span>
                    <span className="truncate text-xs capitalize">{role}</span>
                  </div>
                </div>
              </SidebarMenuButton>
            </SidebarMenuItem>
            <Separator className="mb-2" />
            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                <SidebarRecord />
              </SidebarMenuButton>
            </SidebarMenuItem>
            <Separator className="mt-2" />
          </SidebarMenu>
        </SidebarHeader>

        <SidebarContent>
          <SidebarGroup>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton asChild isActive={isPathActive('/home')}>
                  <div
                    onClick={() => {
                      navigate({
                        to: '/home',
                        replace: true,
                      });
                    }}
                  >
                    <Home className="h-5 w-5" />
                    <span>Home</span>
                  </div>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <NotificationPopup
                    isActive={isPathActive('/notifications')}
                  />
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroup>

          <SidebarGroup>
            <SidebarMenu>
              <SidebarMenuItem key={'manage'}>
                <SidebarMenuButton
                  asChild
                  isActive={isPathActive('/vault/manage')}
                >
                  <div
                    className="flex justify-between items-center text-xs font-medium uppercase text-muted-foreground"
                    onClick={() => {
                      navigate({
                        to: '/vault/$id',
                        params: { id: 'manage' },
                        replace: true,
                      });
                    }}
                  >
                    <span>Vaults</span>
                    <Settings className="w-4 text-gray-500" />
                  </div>
                </SidebarMenuButton>
              </SidebarMenuItem>
              {renderVaultList()}
            </SidebarMenu>
          </SidebarGroup>

          <SidebarGroup className="mt-auto">
            <SidebarGroupContent>
              <SidebarMenu>
                <SidebarMenuItem>
                  <TrialNotice />
                </SidebarMenuItem>
                <Separator />
                {isAuthenticated && !isElectron && (
                  <SidebarMenuItem>
                    <ExtensionStatusButton />
                  </SidebarMenuItem>
                )}
                {!isElectron && (
                  <SidebarMenuItem>
                    <SidebarMenuButton asChild>
                      <a
                        href="https://getfluency.com.au/Download-Windows"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-3 text-xs rounded-lg py-2 transition-all text-muted-foreground hover:text-primary"
                      >
                        <Download className="w-4" /> Download for Windows
                      </a>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                )}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>

        <SidebarFooter>
          <SidebarMenu>
            <SidebarMenuItem>
              <UserDropdown setIsWelcomeOpen={setIsWelcomeOpen} />
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>
      </Sidebar>

      <SidebarInset className="!m-0">
        <header className="sticky top-0 z-20 flex h-10 my-2 shrink-0 items-center gap-2 justify-between px-6 bg-background">
          <div className="flex items-center gap-2">
            <SidebarTrigger className="-ml-1 text-gray-500" />
            <DynamicBreadcrumbs />
          </div>
        </header>
        <main className="h-[calc(100vh-56px-18px)] overflow-auto">
          {children}
        </main>
      </SidebarInset>

      <Welcome isOpen={isWelcomeOpen} onOpenChange={setIsWelcomeOpen} />
    </>
  );
};

export default AppSidebar;
