import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Workflow, GetWorkflowHook } from './types';
import { createHeaders, handleApiError } from './utils';

export const useGetWorkflow: GetWorkflowHook = (workflowId: string) => {
  const { accessToken } = useAuth();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  return useQuery<Workflow, Error>({
    queryKey: ['workflow', workflowId],
    queryFn: async (): Promise<Workflow> => {
      if (!workflowId) {
        throw new Error('Workflow ID is required');
      }

      const requestOptions: RequestInit = {
        method: 'GET',
        headers: createHeaders(accessToken),
        redirect: 'follow',
      };

      try {
        const response = await fetch(
          `${apiBaseUrl}/workflows/${workflowId}`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log(result);
        return result.workflow;
      } catch (error) {
        return handleApiError(error, 'Failed to get workflow');
      }
    },
    enabled: !!workflowId,
  });
};
