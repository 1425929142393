import { useState, useEffect } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import { Card } from '@fluency/ui/components/ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@fluency/ui/components/ui/dropdown-menu';
import {
  FileText,
  Loader2,
  Workflow as WorkflowIcon,
  ArrowRight,
  Plus,
} from 'lucide-react';
import useCreateEmptyDoc from '@fluency/ui/features/Vaults/hooks/useCreateEmptyDoc';
import { useCreateWorkflow } from '@fluency/ui/hooks/workflows';
import useGetVaults from '@fluency/ui/features/Home/hooks/useGetVaults';

export default function SidebarCreateNewDropdown() {
  const { data, isLoading } = useGetVaults();
  const { mutate: createEmptyDoc, isPending: isDocPending } =
    useCreateEmptyDoc();
  const createWorkflowMutation = useCreateWorkflow();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVaultId, setSelectedVaultId] = useState<string>('');

  const vaults = data?.vaults ?? [];

  // Auto-select first vault when data loads
  useEffect(() => {
    if (vaults?.[0]?.id && !selectedVaultId) {
      setSelectedVaultId(vaults[0].id);
    }
  }, [vaults, selectedVaultId]);

  const handleCreateEmptyDoc = () => {
    if (!selectedVaultId) return;

    const newDocPayload = {
      duration: 0,
      name: 'Empty Document',
      description: 'This is a new empty document',
      createdDate: new Date().toISOString(),
      vaultId: selectedVaultId,
    };
    createEmptyDoc(newDocPayload);
    setIsOpen(false);
  };

  const handleCreateWorkflow = () => {
    if (!selectedVaultId) return;

    const workflowPayload = {
      vaultId: selectedVaultId,
      name: 'My New Process Map',
      description: 'My New Process Map',
      nodes: [],
      edges: [],
    };
    createWorkflowMutation.mutate(workflowPayload);
    setIsOpen(false);
  };

  // Changed to only check loading state since selectedVaultId will be auto-selected
  const isActionDisabled = isLoading;

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <Button className="max-h-8 w-full justify-start px-2 gap-2 font-normal">
          <Plus className="h-5 w-5" />
          New
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[36rem] p-4" align="start">
        <div className="space-y-4">
          <div className="flex items-center">
            <div className="line-clamp-1 font-medium text-sm w-28">
              Select vault:
            </div>
            <Select
              value={selectedVaultId}
              onValueChange={setSelectedVaultId}
              disabled={isLoading}
            >
              <SelectTrigger className="w-full">
                <SelectValue
                  placeholder={
                    isLoading ? 'Loading vaults...' : 'Select a vault'
                  }
                />
              </SelectTrigger>
              <SelectContent>
                {vaults.map((vault) => (
                  <SelectItem
                    key={vault.id}
                    value={vault.id}
                    className="cursor-pointer"
                  >
                    {vault.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex gap-4">
            {/* Document Card */}
            <Card className="group relative overflow-hidden transition-all h-full border-0 w-[28rem] hover:border-primary/50">
              <button
                onClick={handleCreateEmptyDoc}
                disabled={isDocPending || isActionDisabled}
                className={`p-4 w-full text-left cursor-pointer ${
                  (isDocPending || isActionDisabled) &&
                  'cursor-not-allowed opacity-60'
                }`}
              >
                <div className="absolute inset-0 bg-gradient-to-br from-primary/5 to-primary/10 opacity-0 group-hover:opacity-50 transition-opacity" />
                <div className="relative">
                  <div className="flex gap-3 items-center mb-3">
                    <div className="bg-primary/10 w-10 h-10 rounded-lg flex items-center justify-center">
                      <FileText className="h-5 w-5 text-primary" />
                    </div>
                    <h3 className="text-base font-semibold">
                      Process Document
                    </h3>
                  </div>
                  <p className="text-xs text-muted-foreground mb-4">
                    Create a new document to capture and standardise your
                    business processes
                  </p>
                  <div className="w-full gap-2 flex items-center justify-center py-2 px-4 text-sm bg-primary/10 text-fluency-600 rounded-md">
                    {isDocPending ? (
                      <Loader2 className="h-4 w-4 animate-spin text-fluency-600" />
                    ) : (
                      <FileText className="h-4 w-4 text-fluency-600" />
                    )}
                    <span>
                      {isDocPending ? 'Creating...' : 'Create New Document'}
                    </span>
                    <ArrowRight className="h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                  </div>
                </div>
              </button>
            </Card>

            {/* Workflow Card */}
            <Card className="group relative overflow-hidden transition-all h-full border-0 w-[28rem] hover:border-purple-500/50">
              <button
                onClick={handleCreateWorkflow}
                disabled={createWorkflowMutation.isPending || isActionDisabled}
                className={`p-4 w-full text-left cursor-pointer ${
                  (createWorkflowMutation.isPending || isActionDisabled) &&
                  'cursor-not-allowed opacity-60'
                }`}
              >
                <div className="absolute inset-0 bg-gradient-to-br from-purple-500/5 to-purple-500/10 opacity-0 group-hover:opacity-50 transition-opacity" />
                <div className="relative">
                  <div className="flex gap-3 items-center mb-3">
                    <div className="bg-purple-500/10 w-10 h-10 rounded-lg flex items-center justify-center">
                      <WorkflowIcon className="h-5 w-5 text-purple-500" />
                    </div>
                    <h3 className="text-base font-semibold">Process Map</h3>
                  </div>
                  <p className="text-xs text-muted-foreground mb-4">
                    Map relationships and flows between processes using
                    interactive diagrams
                  </p>
                  <div className="w-full gap-2 flex items-center justify-center py-2 px-4 text-sm bg-purple-500/10 text-purple-700 rounded-md">
                    {createWorkflowMutation.isPending ? (
                      <Loader2 className="h-4 w-4 animate-spin text-purple-700" />
                    ) : (
                      <WorkflowIcon className="h-4 w-4 text-purple-700" />
                    )}
                    <span>
                      {createWorkflowMutation.isPending
                        ? 'Creating...'
                        : 'Create New Workflow'}
                    </span>
                    <ArrowRight className="h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                  </div>
                </div>
              </button>
            </Card>
          </div>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
