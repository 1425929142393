import React from 'react';
import { transitionStyles } from '../styles';

interface ImageDisplayProps {
  src: string;
  onClick: (e: React.MouseEvent<HTMLImageElement>) => void;
  onLoad: (e: React.SyntheticEvent<HTMLImageElement>) => void;
  onError: () => void;
  isEditMode?: boolean;
}

export const ImageDisplay: React.FC<ImageDisplayProps> = ({
  src,
  onClick,
  onLoad,
  onError,
  isEditMode = false,
}) => (
  <img
    src={src}
    alt="Screenshot"
    className={`block w-full h-auto object-contain transition-all duration-300 ease-in-out ${
      isEditMode ? 'cursor-pointer' : 'cursor-default'
    }`}
    onClick={onClick}
    onLoad={onLoad}
    onError={onError}
    style={transitionStyles.fadeIn}
  />
);
