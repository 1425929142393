// ImageEditor.tsx
import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { Button } from '@fluency/ui/components/ui/button';
import { Sidebar } from './components/Sidebar';
import { Canvas } from './components/Canvas';
import { PenSquareIcon } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { ScreenshotType } from '../../types/master';
import { NormalizedCropData } from './types';
import { validateNormalizedCrop } from './utils/crop';
import { RedactionRectangle, RedactionColor } from './types';
import useScreenshotCrop from '../../hooks/useScreenshotCrop';

interface ImageEditorProps {
  documentationId: string;
  stepId: string;
  screenshot: ScreenshotType;
}

export const ImageEditor: React.FC<ImageEditorProps> = ({
  documentationId,
  stepId,
  screenshot,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeFeature, setActiveFeature] = useState<string | null>(null);
  const [cropData, setCropData] = useState<NormalizedCropData>({
    x: 25.0,
    y: 25.0,
    width: 50.0,
    height: 50.0,
  });
  const [aspectRatio, setAspectRatio] = useState<number | null>(null);
  const screenshotCrop = useScreenshotCrop({ documentationId });

  // Redaction state
  const [redactionRectangles, setRedactionRectangles] = useState<
    RedactionRectangle[]
  >([]);
  const [selectedRectangles, setSelectedRectangles] = useState<number[]>([]);
  const [currentRedactionColor, setCurrentRedactionColor] =
    useState<RedactionColor>('blur');

  const handleCropChange = (newCrop: NormalizedCropData) => {
    if (validateNormalizedCrop(newCrop)) {
      setCropData(newCrop);
    }
  };

  const handleAspectRatioChange = useCallback(
    (ratio: number | null) => {
      if (!cropData) return;

      setAspectRatio(ratio);
      if (ratio) {
        const newHeight = cropData.width / ratio;
        setCropData({
          ...cropData,
          height: newHeight,
          y: cropData.y - (newHeight - cropData.height) / 2,
        });
      }
    },
    [cropData]
  );

  const handleSaveCrop = async () => {
    if (!cropData || !validateNormalizedCrop(cropData)) {
      console.error('Invalid crop data');
      return;
    }

    try {
      const cropUpdate = {
        screenshotCropUpdates: [
          {
            screenshotId: screenshot.screenshotId,
            cropInfo: {
              x1Coordinate: Number(cropData.x.toFixed(2)),
              y1Coordinate: Number(cropData.y.toFixed(2)),
              x2Coordinate: Number((cropData.x + cropData.width).toFixed(2)),
              y2Coordinate: Number((cropData.y + cropData.height).toFixed(2)),
            },
            isCropped: true,
          },
        ],
      };

      await screenshotCrop.mutateAsync(cropUpdate);
      setIsOpen(false);
    } catch (error) {
      console.error('Failed to save crop:', error);
    }
  };

  const handleSaveRedactions = async () => {
    try {
      const redactionDataForSaving = redactionRectangles.map((rect) => ({
        ...rect,
        x: Number(rect.x.toFixed(2)),
        y: Number(rect.y.toFixed(2)),
        width: Number(rect.width.toFixed(2)),
        height: Number(rect.height.toFixed(2)),
      }));
      console.log('Saving redactions:', redactionDataForSaving);
      // await saveRedactionData(screenshotId, redactionDataForSaving);
      setIsOpen(false);
    } catch (error) {
      console.error('Failed to save redactions:', error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <DialogTrigger asChild>
              <Button variant="ghost" size="sm">
                <PenSquareIcon className="w-4 h-4" />
              </Button>
            </DialogTrigger>
          </TooltipTrigger>
          <TooltipContent side="right">
            <p>Edit Image</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <DialogContent className="max-w-[90vw] max-h-[90vh] p-0 overflow-hidden">
        <DialogHeader className="px-6 py-4">
          <DialogTitle>Edit Image</DialogTitle>
        </DialogHeader>

        <div className="flex h-[calc(90vh-5rem)] relative">
          <div className="flex flex-col h-full">
            <div className="flex-1 overflow-hidden">
              <Sidebar
                imageKey={screenshot.screenshotKey}
                stepId={stepId}
                documentationId={documentationId}
                screenshot={screenshot}
                activeFeature={activeFeature}
                onFeatureChange={setActiveFeature}
                cropData={cropData}
                aspectRatio={aspectRatio}
                onAspectRatioChange={handleAspectRatioChange}
                onSaveCrop={handleSaveCrop}
                redactionRectangles={redactionRectangles}
                onRedactionRectanglesChange={setRedactionRectangles}
                selectedRectangles={selectedRectangles}
                currentRedactionColor={currentRedactionColor}
                onRedactionColorChange={setCurrentRedactionColor}
                onSaveRedactions={handleSaveRedactions}
              />
            </div>
            <div className="p-4">
              <DialogClose asChild>
                <Button variant="secondary" className="w-full">
                  Close
                </Button>
              </DialogClose>
            </div>
          </div>
          <div className="flex-1 min-w-0 overflow-hidden bg-muted/5">
            <Canvas
              screenshot={screenshot}
              documentationId={documentationId}
              activeFeature={activeFeature}
              cropData={cropData}
              aspectRatio={aspectRatio}
              onCropChange={handleCropChange}
              redactionRectangles={redactionRectangles}
              onRedactionRectanglesChange={setRedactionRectangles}
              selectedRectangles={selectedRectangles}
              onSelectedRectanglesChange={setSelectedRectangles}
              currentRedactionColor={currentRedactionColor}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
